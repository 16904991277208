button.mat-flat-button {
  &.mat-primary {
    background-color: var(--main-title-color);
    .mat-button-wrapper {
      color: var(--primary-button-text);
    }
  }
  &.mat-accent {
    background-color: var(--accent-button-background);
    .mat-button-wrapper {
      color: var(--primary-button-text);
    }
  }

  &.success-btn {
    background-color: var(--success-btn-background);
    color: var(--primary-button-text);
  }
}

a.mat-mini-fab, button.mat-mini-fab {
  &.mat-primary {
    background-color: var(--main-title-color);
    mat-icon {
      color: var(--primary-button-text);
    }
    &:hover {
      background-color: var(--main-title-color-hover);
    }
  }
}

button.mat-raised-button {
  .mat-button-wrapper {
    color: var(--primary-button-text);
  }
  mat-icon {
    margin-right: 4px;
  }
}


button.mat-flat-button {
  &.mat-primary, &.mat-accent {
    .mat-button-wrapper {
      color: var(--primary-button-text);
    }
  }
  &.mat-warn {
    .mat-button-wrapper {
      color: var(--primary-button-text);
    }
  }
}

button.mat-stroked-button {
  min-width: unset;
  &.mat-primary {
    border-color: var(--main-title-color);
    .mat-button-wrapper {
      color: var(--main-text-color);
    }
    .mat-button-focus-overlay {
      background: var(--main-title-color);
    }
  }
}

button.mat-icon-button {
  &:hover {
    mat-icon {
      opacity: 0.7;
    }
  }
  &:focus {
    outline: var(--main-border-color) 5px;
  }

  &.remove-btn {
    background-color: var(--error-background);
    color: var(--primary-button-text);
  }
}
