app-consultation-collected-data {
  --column-gap: 24px;
  @mixin grid-min-max-cols($min-cols, $max-cols, $cols-min-width, $grid-row-gap: 0px, $grid-column-gap: 0px) {
    --min-cols: #{$min-cols};
    --max-cols: #{$max-cols};
    --cols-min-width: #{$cols-min-width};
    --grid-row-gap: #{$grid-row-gap};
    --grid-column-gap: #{$grid-column-gap};

    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(min((100%/var(--min-cols) - var(--grid-column-gap)*(var(--min-cols) - 1)/var(--min-cols)),
        max(var(--cols-min-width),
          (100%/var(--max-cols) - var(--grid-column-gap)*(var(--max-cols) - 1)/var(--max-cols)))), 1fr));
    gap: $grid-row-gap $grid-column-gap;
  }
  .mat-expansion-panel-header {
    padding: 0 10px;
  }
  .mat-expansion-panel-body {
    padding: $sp-md 10px;
  }
  .p-divider.p-divider-vertical {
    margin: 0 $sp-xs;
    padding: 0;
  }
  p-divider {
    position: absolute;
    left: -16px;
  }
  .group-name {
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    padding-bottom: $sp-xs;
    line-height: 24px;
  }

  .point-grid {
    width: 100%;
    display: grid;
    @include grid-min-max-cols(1, 10, 180px, $sp-sm, var(--column-gap));
    overflow: hidden;
  }

  .point-container {
    position: relative;
    display: flex;
    flex-direction: column;
    column-gap: $sp-xs;
  }

  .point-label {
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    margin: 0;
    color: var(--main-title-color);
    padding-bottom: $sp-xs;
    line-height: 16px;
    flex-shrink: 0;
  }

  .point-value {
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    margin: 0;
    color: var(--secondary-text-color);
    padding-bottom: $sp-xs;
    line-height: 16px;
    max-width: 75ch;
    ul {
      margin-block-start: 0px;
      margin-inline-start: 0px;
      padding-inline-start: 18px;
    }
  }

  .link-btn {
    background: #26C2EB;
    padding: 2px $sp-sm;
    border-radius: 8px;
    position: relative;
    margin-bottom: $sp-sm;
    mat-icon {
      --cadesi-icon-size: 18px;
      width: var(--cadesi-icon-size);
      height: var(--cadesi-icon-size);
      line-height: var(--cadesi-icon-size);
      font-size: var(--cadesi-icon-size);
    }
    span {
      margin-left: $sp-xs;
      color: white;
      font-size: 13px;
      font-weight: 600;
    }
  }
  app-dynamic-consultation-media {
    display: block;
    margin: $sp-md 0 $sp-sm 0;
  }
}
