app-add-edit-standalone-appointments {
  display: block;
  --header-height: #{$header-height};
  --sidebar-width: #{$menu-width};
  --sidebar-width-offset: #{$menu-width + $menu-offset};

  .radio-input-holder {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .input-holder {
    margin-bottom: 10px;
  }

  .appointment-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .add-client-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .loading {
    filter: blur(2px);
  }

  .appointment-form-container {
    width: 300px;
    max-width: calc(100vw - var(--sidebar-width-offset));
    min-width: 33vw;
    position: relative;

    .radio-btn {
      padding: 0 8px 8px 0;
    }
  }

  .back-nav {
    position: absolute;
    left: 0px;
  }

  .inputs-container {
    mat-select {
      margin-bottom: 8px;
    }
  }
  mat-label {
    font-size: 14px;
    font-weight: $bold-default;
    display: block;
    margin-bottom: 4px;
  }
  .date-input-container {
    position: relative;
    padding: 4px 0px;
    margin-bottom: $sp-sm;
    border-bottom: 1px solid;
    min-height: 30px;
    border-color: var(--main-border-color);
    .calendar-trigger {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    mat-icon {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      font-size: 18px;
      cursor: pointer;
    }
    &.disabled {
      border-color: rgba(0,0,0,.12);
      mat-icon {
        color: rgba(0,0,0,.12);
      }
    }
  }
  .disabled {
    pointer-events: none;
  }

  .appointments-header {
    padding: $sp-md 0;
    width: calc(100% - var(--sidebar-width));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .appointment-card {
    @include flex($flex-direction: column, $justify-content: flex-start, $align-items: center);
    margin-right: 10px;
    padding: 0;
    position: relative;
    border-radius: $panel-border-radius;

    .appointment-card-header {
      position: relative;
      background: var(--main-title-color);
      width: 100%;
      border-top-left-radius: $panel-border-radius;
      border-top-right-radius: $panel-border-radius;
      padding: $sp-sm;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 500;
        font-size: 16px;
        color: white;
        strong {
          color: white;
        }
        &.appointment-type {
          display: block;
          padding-left: $sp-lg;
          font-size: 14px;
          font-weight: 400;
          color: #e6e6e6;
          //font-style: italic;
        }
      }
    }

    .appointment-card-content {
      padding: 10px;
      align-self: flex-start;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .actions {
        align-self: center;
        justify-self: flex-end;
      }
    }

    .appointment-date {
      display: flex;
      align-items: center;
      mat-icon {
        color: white;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    p {
      margin-bottom: 10px;
    }

    p-message {
      margin-bottom: $sp-sm;
      display: inline-block;
    }

    &.appointment-error {
      border-color: var(--error-background);
      .appointment-card-header {
        background: var(--error-background-faded);
        .appointment-type {
          color: var(--secondary-text-color);
        }
        .appointment-date *, .appointment-type * {
          color: var(--main-text-color);
        }
      }
    }
  }
  .appointments-grid {
    width: 100%;
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: repeat( auto-fit, minmax(350px, 35%) );
    grid-auto-rows: minmax(min-content, max-content);
    justify-content: center;
    align-items: start;
  }
  .page-actions {
    margin-top: $sp-md;
    margin-bottom: $sp-lg;
    * {
      margin: 0 $sp-sm;
    }
  }

  .calendar-loading-holder {
    background: rgba(black, 0.3);
    position: fixed;
    border-radius: 3px;
    padding: $sp-md $sp-xl;
    width: calc(100vw - var(--sidebar-width));
    height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: var(--header-height);
    left: var(--sidebar-width);
    pointer-events: none;
    z-index: 1000;
  }

  .practice-input {
    margin-bottom: $sp-sm;
  }
}

.treatment-plan-dialog-calendar-picker-actions {
  @include flex($align-items: center, $justify-content: flex-end);
  width: 100%;
  margin-top: 8px;
  mat-label {
    margin-right: 8px;
  }
  .cancel-btn {
    margin-right: 8px;
  }

  .appointment-dialog-content {
    overflow: hidden;
  }

  .pet-selection-margin {
    margin-bottom: 150px;
  }
}

.treatment-plan-dialog-calendar-error {
  margin-top: $sp-sm;
  text-align: right;
}

