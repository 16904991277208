$status-size: 8px;

.online-status {
  min-width: $status-size;
  min-height: $status-size;
  margin-right: 8px;
  display: block;
  border-radius: 50%;
  background: orange;
  &.online {
    background: $bright-green;
  }
  &.away {
    background: lightgrey;
  }
}
