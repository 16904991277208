@mixin dark-theme {
  --main-body-background: #{$dark-background-grey};
  --main-text-color: #{$main-white};
  --secondary-text-color: #{$mid-grey};
  --success-text-color: #{$bright-green};
  --warning-text-color: #{$bright-orange};
  --danger-text-color: #{$red-mid};
  --login-background: #{$dark-background-grey};
  --login-input-background: #{$pale-blue-deepen};
  --header-background: #{$dark-background-tint};
  --header-border-color: #{$dark-background-highlight};
  --pet-profile-border-color: #{$main-white};
  --main-title-color: #{$dark-main-title};
  --main-title-color-hover: #{$pale-blue-highlight};
  --secondary-title-color: #{$dark-secondary-text};
  --main-panel-color: #{$dark-background-grey};
  --main-panel-color-hover: rgba(#{$dark-background-grey}, 0.7);
  --main-border-color: #{$dark-background-highlight};
  --panel-background: #{$dark-background-tint};
  --expand-panel-background: #{$dark-background-highlight};
  --autocomplete-active-background: #{$midnight-grey};
  --search-border-color: #{$dark-background-highlight};
  --search-background: #{$dark-background-tint};
  --table-background: #{$dark-background-tint};
  --table-background-disabled: #{$dark-background-tint-disabled};
  --table-header-background: #{$dark-background-highlight};
  --table-row-hover-background: #{lighten($dark-background-tint, 5%)};
  --primary-button-text: #{$main-white};
  --accent-button-background: #{$bright-orange};
  --success-btn-background: #{$bright-green};
  --input-toggle-dark-background: #{$pale-blue-deepen};
  --input-background: #{$dark-background-highlight};
  --warning-background: #{$bright-orange};
  --warning-background-faded: #{$orange-mid-faded};
  --error-background: #{$dark-warning-background};
  --error-background-faded: #{$red-mid};
  --success-background: #{$bright-green};
  --success-background-faded: #{$green-mid-faded};
  --progress-spinner-stroke: #{$pale-blue-highlight};
  --button-hover-color: rgba(255,255,255,.7);
  --video-call-background-color: #{$dark-background-deep};
  --overlay-background: rgba(#{$dark-grey}, .7);
  --skeleton-gradient: linear-gradient(90deg, rgba(#{$skeleton-animation-dark}, 0) 0, rgba(#{$skeleton-animation-dark}, 0.2) 20%, rgba(#{$skeleton-animation-dark}, 0.5) 60%, rgba(#{$mid-grey}, 0));
  --rota-block-background-color: #{$red-mid};
  --rota-appointment-background-color: 2,117,216;
  --rota-rota-background-color: 102,213,121;
  --disabled-accent: #{$dark-background-highlight};
  --prime-sidepanel-btn-hover-background: #{$dark-background-highlight};
  --edd-signature-background: #{$main-white};
  --prime-table-header: #{$dark-background-highlight};
  --prime-table-data-row: #{$dark-background-tint};
  --prime-table-border-color: #{$dark-secondary-text};
  --prime-dropdown-selected-option: #{rgba($pale-blue-highlight, 0.4)};
  --consultation-section-background: #{$dark-background-grey};
  --profile-preview-background: #{$main-white};
  --profile-preview-text: #{$main-black};
  --pom-product-highlight: #{$purple-dark};
  --address-panel-background: #{$dark-grey};
  --basket-total-background: #{$pale-blue-deepen};
  --basket-sidebar-background: #{$dark-background-tint};
  --basket-address-icon-fill: #{$main-white};
  --basket-processing-background: #{$bright-blue};
  --basket-complete-background: #{$dark-green};
  --outline-button-text: #{$main-white};
}
