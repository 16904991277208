.mat-calendar {
  background: var(--table-background);

  .mat-calendar-arrow {
    fill: var(--main-text-color);
  }

  .mat-calendar-table-header, .mat-calendar-body-label {
    color: var(--main-text-color);
  }

  .mat-calendar-table-header-divider:after {
    background: var(--main-border-color);
  }
  .mat-calendar-previous-button, .mat-calendar-next-button {
    color: var(--main-text-color);
  }
  .mat-calendar-body-cell-content {
    color: var(--main-text-color);
  }
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--main-title-color);
  }
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--main-title-color);
    color: white;
  }
  .mat-calendar-body-selected {
    background-color: var(--main-title-color);
    color: white;
  }
  .mat-calendar-body-in-comparison-range::before {
    background: rgba($pale-blue, .2);
  }
  .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
    background: rgba($pale-blue, .2);
  }
}

.mat-datepicker-actions {
  background: var(--table-background);
}

.mat-datepicker-close-button {
  display: none !important;
}
