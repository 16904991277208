app-main-header {
  --menu-width: #{$menu-width};
  position: relative;
  .main-header {
    width: 100%;
    height: $header-height;
    border-bottom: 1px solid;
    background: var(--header-background);
    border-color: var(--header-border-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;

    .logo-holder {
      width: 100px;
      display: inline-block;
      padding: 10px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .version-details {
      display: inline-block;
      width: 100%;
      position: absolute;
      top: 17px;
      left: calc(var(--menu-width) / 2 + 2px);
      text-align: center;
    }
    .actions {
      padding-right: $sp-sm;
      display: inline-flex;
      position: absolute;
      top: 17px;
      right: $header-offset;
      text-align: right;
      mat-icon {
        cursor: pointer;
        display: block;
        opacity: 0.5;
        &.selected {
          opacity: 1;
        }
      }
    }
  }

  .main-nav {
    width: $menu-width;
    height: 100%;
    border-right: 1px solid;
    background: var(--header-background);
    border-color: var(--header-border-color);
    position: fixed;
    padding-top: $header-offset;
    top: $header-height;
    left: 0;
    z-index: 999;

    .menu-item {
      text-align: center;
      margin-bottom: 15px;
      cursor: pointer;

      &.is-active a {
        color: var(--main-text-color);
      }

      a {
        width: 100%;
        height: 100%;
        display: inline-block;
        color: var(--secondary-title-color);
      }

      span {
        width: 100%;
        display: inline-block;
        font-size: 12px;
      }

      .mat-icon {
        width: $menu-icon-size;
        height: $menu-icon-size;
        font-size: $menu-icon-size;
      }
    }
  }
  app-theme-selection {
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
