.client-basket-sidebar {
  .p-sidebar-content {
    padding: 0;
  }
}

app-client-basket {
  .client-basket-holder {
    --basket-tab-height: 93px;
    --basket-content-padding: 0px;
    --basket-header-footer: 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .printer-retry {
    .p-error {
      padding-left: 10px;
      position: relative;
      top: -10px;
    }
  }

  .loading-icon {
    font-size: 20px;
    line-height: 20px;
  }

  .checkout-manager {
    width: 50%;
    display: inline-block;
    padding: 0 1.25rem;
    margin-bottom: 20px;
    .card-reader-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .p-dropdown {
      width: 100%;
    }
  }

  .p-scrollpanel {
    height: calc(
      100vh
      - var(--basket-tab-height)
      - var(--basket-content-padding)
      - var(--basket-header-footer)
    );
    width: 100%;
    transition: all 0.1s linear;
  }

  .p-scrollpanel .p-scrollpanel-bar {
    background: var(--main-title-color);
  }

  .address-icon {
    width: 26.621px;
    height: 27.998px;
    flex-shrink: 0;
    margin-right: $sp-md;
  }

  .p-tabview {
    padding: 0 1.25rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 1.25rem 0;
  }

  .p-tabview-title {
    font-size: 16px;
    font-weight: 500;
  }
  .address-manager {
    padding: 0 1.25rem;
  }
  header.basket-header {
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    span {
      display: flex;
      align-items: flex-end;
      h1 {
        font-size: 2rem;
        margin: 0;
      }
      i {
        margin-right: $sp-md;
        color: var(--main-title-color);
        top: -0.4rem;
        position: relative;
      }
    }
    .close-button {
      background: unset !important;
      border-color: var(--main-text-color);
      border: 0;
      position: relative;
      top: 0.1rem;
      span {
        color: var(--main-text-color);
        font-size: 1.5rem;
      }
      &:hover {
        span {
          opacity: 0.6;
        }
      }
    }
  }
  .footer {
    position: fixed;
    width: 35rem;
    bottom: 0;
    z-index: 100;
    background: var(--basket-sidebar-background);
  }
  .actions-holder {
    overflow: hidden;
    min-height: 0;
    &.inactive {
      max-height: 130px;
      height: 130px;
      padding: 0 30px;
      transition: max-height 0s ease, padding 0s ease;
    }
    &.active {
      max-height: 1000px;
      height: unset;
      padding: 0 30px;
      transition: max-height 0.2s ease-in, padding 0.2s ease-in;
    }
    .address-holder {
      display: flex;
      margin-top: 24px;
      padding: 10px;

      path {
        fill: var(--basket-address-icon-fill);
      }
    }
    .address-content {
      h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        color: var(--main-text-color);
        margin-bottom: 0;
      }
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        strong {
          text-transform: unset;
        }
      }
    }
    .address-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      a {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--main-title-color);
        transition: all 0.1s linear;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      .p-button-label {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .p-button-outlined {
        .p-button-label {
          color: var(--main-title-color);
        }
      }
      .p-button {
      }
      .checkout .p-button {
        margin-right: 10px;
      }
    }
  }
  .total-holder {
    background: var(--basket-total-background);
    padding: 24px;
    display:flex;
    justify-content: space-between;
    width: 100%;

    &.processing {
      background: var(--basket-processing-background);
    }

    &.complete {
      background: var(--basket-complete-background);
    }

    .payment-icon {
      margin-right: $sp-sm;
    }

    span {
      font-size: 22px;
      line-height: 28px;
      color: $main-white;
    }

    .approx-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .pending-list-holder, .selected-address-holder {
    background: var(--consultation-section-background);
    border: 1px solid var(--main-border-color);
    border-radius: 3px;
    padding: 10px;
  }

  .address-holder {
    .address-content {
      width: 100%;
    }
  }

  .empty-container {
    margin-top: 20px;
    span {
      color: #EB6B0D;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: $sp-md;
    }
  }
}
// Don't usually adda a global scope var but is being appended to body rather than local instance
.client-basket-sidebar {
  .p-sidebar-right {
    width: 35rem;
    .p-sidebar-header {
      display: none;
    }
  }
}
