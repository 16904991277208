$asda-list-view-icon-size: (
  height: 16px
);

$afi-list-view-icon-size: (
  height: 35px
);

$purely-pets-list-view-icon-size: (
  height: 35px
);

$waggel-list-view-icon-size: (
  height: 22px
);

p-tag {
  display: block;
  margin-top: $sp-xs;
  img {
    height: 35px;
  }
}

.p-tag {
  background: #02a02f;
}

.trial-confirm-actions{
  display: flex;
  justify-content: space-between;
}

.appointment-queue-card, .home-queue-card {
  margin: $card-padding;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.1);
  border: 1px solid;
  border-color: var(--main-border-color);
  border-radius: 5px;

  p {
    margin: 0;
    cursor: default;
    color: var(--main-text-color);
  }

  .pet-name {
    font-weight: $default-weight;
  }

  &.appointment {
    border-color: $appointment-highlight;
  }

  &.specialist {
    border-color: $specialist-appointment-highlight;
  }

  &.yumove {
    border-color: #47138f;
  }

  &.disabled {
    border-color: #ccc;
  }

  &.trial {
    border-color: $trial-consultation-color;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .appointment-display-list {
    width: 100%;
    .appointment-display-content {
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      &.appointment {
        background: rgba($appointment-highlight, .3);
      }
      &.specialist {
        background: rgba($specialist-appointment-highlight, .3);
      }
      &.yumove {
        background: rgba(#601cc5, .3);
      }
      &.trial {
        background: $trial-consultation-color;
      }
      &.disabled {
        background: rgba(#ccc, .3) !important;
      }
      mat-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        margin-top: 1px;
        cursor: default;
        color: var(--main-text-color);
      }
      p {
        margin: 0;
        font-size: 10px;
        cursor: default;
        color: var(--primary-button-text);
      }
      .staff {
        font-weight: $bold-default;
      }
    }
  }

  .appointment-display {
    width: 100%;
    .appointment-display-content {
      width: 100%;
      color: var(--primary-button-text);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 8px;
      display: flex;
      justify-content: space-between;

      mat-icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        margin-top: 1px;
        cursor: default;
        color: var(--main-text-color);
      }
      .staff {
        font-weight: $bold-default;
      }

      &.in-progress, &.practice-confirmed {
        background: #6ED813;
      }
      &.waiting, &.practice-pending {
        background: #E87511;
      }
      &.pending {
        background: $purple-mid;
      }
      &.appointment {
        background: rgba($appointment-highlight, .3);
      }

      &.specialist {
        background: rgba($specialist-appointment-highlight, .3);
      }
      &.yumove {
        background: rgba(#601cc5, .3);
      }
      &.trial {
        background: rgba($trial-consultation-color, .3);
      }
      &.disabled {
        background: rgba(#ccc, .3);
      }
      &.in-review, &.in-progress, &.post-review {
        background: $appointment-confirmed-color;
      }
      &.terminated, &.practice-terminated {
        background: var(--error-background);
      }
      &.completed, &.practice-completed {
        background: var(--table-background-disabled);
      }
    }
  }

  .card-content {
    padding: $card-padding;
    cursor: default;
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    p {
      margin-bottom: 0;
    }
  }

  .icon-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap-reverse;

    .mat-progress-spinner {
      margin-left: 16px;
    }

    mat-icon {
      position: relative;
      color: var(--main-title-color);
      margin-left: 10px;
      &:after {
        content: "";
        position: absolute;
        left: -3px;
        top: -3px;
        width: 30px;
        height: 30px;
      }
      &:hover {
        color: var(--main-title-color-hover);
        cursor: pointer;
      }
    }
    .disabled {
      color: #ccc !important;
      &:hover {
        cursor: not-allowed !important;
      }
    }
  }

  .email {
    color: var(--secondary-title-color);
    font-style: italic;
  }
  .pet-details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    * {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    p {
      margin-bottom: 0;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  .pet-profile-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    app-pet-profile-image {
      margin-right: 24px;
    }
    .insurance-list {
      margin-right: 8px;
      &.afi {
        width: auto;
        height: map-get($afi-list-view-icon-size, height);
        border-radius: 0;
      }
      &.asda {
        width: auto;
        height: map-get($asda-list-view-icon-size, height);
        border-radius: 0;
      }
      &.purely-pets {
        width: auto;
        height: map-get($purely-pets-list-view-icon-size, height);
        border-radius: 0;
      }
      &.waggel {
        width: auto;
        height: map-get($waggel-list-view-icon-size, height);
        border-radius: 0;
        margin-bottom: $sp-xs;
      }
    }
  }

  .list-view-container {
    @include flex($align-items: center, $justify-content: space-between);
    &.consult {
      min-width: 300px;
    }
    p {
      margin: 0;
    }
    .pet-profile-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      app-pet-profile-image {
        margin-right: 10px;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    .icon-container {
      mat-icon {
        margin-left: 8px;
        margin-bottom: 0;
      }
    }
    span {
      &.green {
        color: var(--success-background);
      }
      &.orange {
        color: var(--warning-background);
      }
      &.red {
        color: var(--error-background);
      }
    }
  }

  .info-icon {
    width: 18px;
    height: 18px;
    line-height: 14px;
    font-size: 18px;
    position: relative;
    top: 4px;
    margin-left: $sp-xs;
    &.not-available {
      color: var(--error-background);
    }
    &.available {
      color: var(--success-background);
    }
  }

  .no-insurance {
    width: 18px;
    height: 18px;
    margin-bottom: $sp-md;
    line-height: 14px;
    font-size: 18px;
    position: relative;
    top: 4px;
    color: var(--error-background);
  }
  p-tag {
    margin-top: $sp-xs;
    display: block;
  }

  .card-header {
    .header-details {
      width: 65%;
    }
  }
}
