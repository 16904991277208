app-product-picker {
  .venom-codes-holder {
    width: 110%;
    padding: 10px;
    background: var(--table-background);
    position: absolute;
    top: 45px;
    z-index: 999;
    border: 1px solid;
    border-color: var(--main-border-color);
    margin-bottom: 20px;
    max-height: 250px;
    overflow: auto;
  }

  .input-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 997;
  }

  .no-results p {
    margin-bottom: 0;
  }

  .inputs-holder {
    position: relative;
  }

  .expanded.category-label, .expanded .mat-icon {
    color: black !important;
  }

  .venom-codes-holder ul {
    padding-left: 10px;
    margin-bottom: 0;
  }

  .venom-codes-holder ul.root-list {
    padding-left: 0;
  }

  .search-holder {
    position: relative;
    margin-bottom: 10px;
    z-index: 1;
    span.p-input-icon-right {
      width: 100%;
    }
    .close-icon {
      cursor: pointer;
    }
  }

  .search-holder.drop-down-expanded {
    z-index: 998;
  }

  .search-holder .form-control {
    height: 36px;
    text-overflow: ellipsis;
    padding-left: 8px;
    padding-right: 60px;
    padding-top: 0;
    padding-bottom: 0;
    color: var(--main-text-color);
    background: none;
    border: none;
    border-bottom: 1px solid;
    border-color: var(--main-border-color);
    border-radius: 0;
    font-size: 14px;
    width: 100%;
    margin-bottom: 24px;
  }

  .search-holder .form-control::placeholder {
    color: var(--main-text-color);
  }

  .clear-btn {
    position: absolute;
    top: calc(50% - 12px);
    height: 24px;
    right: 7px;
    cursor: pointer;
    transform: translateY(-50%);
  }

  .info-btn {
    position: absolute;
    top: -2px;
    right: 30px;
    cursor: pointer;
  }

  .nested-level {
    padding-left: 10px;
  }

  .product-item {
    width: 100%;
    display: inline-block;
    padding: 10px;
    font-style: italic;
    font-weight: normal;
    cursor: pointer;
    color: var(--secondary-text-color);
    border: 1px solid #999;
    margin-top: -1px;
  }

  .product-item .mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 12px;
    position: relative;
    top: 4px;
  }

  .product-container {
    position: relative;
    display: block;
    .unavailable-text {
      content: 'Product unavailable';
      color: var(--secondary-text-color);
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      @include flex($justify-content: center, $align-items: center);
      mat-icon {
        margin-right: 8px;
      }
    }
  }

  .product-unavailable {
    opacity: 0.1;
    cursor: not-allowed;
  }

  .product-unavailable div {
    opacity: 0.4;
  }

  .category-item {
    font-size: 13px;
    font-weight: $bold-default;
    cursor: pointer;
    user-select: none;

    .pom-details {
      width: 210px;
      text-align: center;
      position: absolute;
      top: 20px;
      left: 23%;

      .mat-icon {
        color: var(--primary-button-text);
      }

      span {
        position: relative;
        top: -5px;
      }
    }
  }

  .category-label {
    font-size: 13px;
    color: #999;
    position: relative;
    top: -6px;
  }

  .category-item .mat-icon {
    color: #999;
  }

  .medication-selected-text {
    padding-left: 5px;
    font-size: 14px;
    color: #555;
    border-bottom: 2px solid;
    border-color: var(--main-border-color);
  }
  .medication-selected, .medication-input {
    padding-left: 5px;
    font-size: 14px;
    color: #555;
    border-radius: 0;
    width:100%;
    border: none;
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  .dropdown-label {
    font-size: 14px;
    padding-left: 8px;
    color: #11dee3;
    text-transform: uppercase;
  }

  hr {
    margin: 0;
    color: #ccc;
    border-top: dashed 1px;
  }
  textarea {
    max-height: 50vh;
    width: 100%;
    color: inherit;
    background: transparent;
    border: 0;

  }
  .underline {
    border-bottom: 1px solid #ccc;
  }

  .product-price {
    float: right;
    position: relative;
    top: 5px;
    color: #26C2EB;
  }

  .treatment-textarea {
    font-size: 12px;
    border: 1px solid #ccc;
  }
  textarea::placeholder {
    color: #ccc;
    font-style: italic;
  }
  .treatment-header {
    padding:0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color:#eee;
  }
  .treatment-footer {
    text-align: center;
    padding: 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color:#eee;
  }

  .textarea-instructions {
    border: 1px solid #07b57e;
  }

  .add-button {
    padding: 5px 15px;
    margin-bottom: 5px;
    border-radius: 40px;
    background-color: #d3f1f1;
    text-align: center;
    display: inline-block;
  }
  .add-button:hover {
    cursor: pointer;
    background-color: #b9eceb;
  }

  .product-details {
    display: block;
    font-size: 12px;
  }

  .medication-legal-category {
    color: rgb(40, 90, 226);
  }
  .medication-pack-size {
    color: #25c2eb;
  }
  .medication-in-stock {
    color: rgb(0, 196, 0);
  }
  .medication-out-of-stock {
    color: darkorange;
  }
  .product-tooltip {
    margin-right: 4px;
  }
  .p-inputtext {
    width: 100%;
  }
}
