app-theme-selection {
  mat-icon {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    line-height: 22px !important;
    margin-top: -2px;
  }

  .active {
    background: var(--main-panel-color) !important;
    &:hover {
      background: var(--main-panel-color) !important;
    }
  }

}
