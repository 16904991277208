//Colours
$main-white: #FFF;
$main-black: #000;
$main-grey: #f8f8f8;
$mid-grey: #c7c7c7;
$pale-grey: #E3F2FD;
$deep-grey: #737373;
$dark-grey: #212429;
$dark-grey-rgb: 33, 36, 41;
$midnight-grey: #414751;
$pale-blue: #697DA3;
$pale-blue-highlight: #859dcc;
$pale-blue-deepen: #47556e;
$bright-green: #25c440;
$green-mid-faded: #66d579;
$green-light: #C8E6C9;
$bright-red: #ad1c2a;
$bright-orange: #de950d;
$orange-mid-faded: #e7b455;
$orange-light: #FFECB3;
$error-red: #f44336;
$bright-blue: #2c73d2;
$dark-green: #003b2a;
// Problem Colour Schemes
$teal-mid: #1CB7A0;
$teal-pale: #CAF3ED;
$teal-dark: #00826E;
$cyan-mid: #61E3E7;
$cyan-pale: #B9FDFF;
$cyan-dark: #56C6CA;
$purple-mid: #BE82EB;
$purple-pale: #E9CBFF;
$purple-dark: #8F3ED3;
$orange-mid: #F08006;
$orange-pale: #f9dcba;
$orange-dark: #AA6625;
$red-mid: #CF6679;
$red-pale: #FFCDD2;
$red-dark: #BF141E;
// Appointment colours
$appointment-highlight: #1babd4;
$specialist-appointment-highlight: #17b000;
$appointment-denied-color: $bright-red;
$appointment-pending-color: $orange-mid;
$appointment-confirmed-color: #0275d8;
$appointment-completed-color: $bright-green;
$appointment-new-booking-color: #920de9;
$appointment-unavailable-color: $deep-grey;
//Charity Colours
$helpful-hounds-blue: #090084;
$our-special-friends-orange: #e16206;
//Trial colours
$trial-consultation-color:  #FFA41B;
// Number Vars
$login-form-max-width: 300px;
$header-height: 60px;
$header-offset: 15px;
$panel-padding: 10px;
$panel-border-radius: 10px;
$header-one-size: 20px;
$header-two-size: 16px;
$paragraph-size: 14px;
$menu-width: 70px;
$menu-offset: 10px;
$menu-icon-size: 35px;
$expand-panel-thumbs-size: 70px;
$card-padding: 8px;
$search-height: 40px;
$search-font-size: 18px;
$search-select-width: 100px;
$search-date-picker-width: 210px;
$search-select-height: 30px;
$search-input-width: calc(100% - 85px);
$button-border-radius: 5px;
$general-input-height: 36px;
$table-header-font-size: 12px;
// Skeleton loading
$skeleton-animation-dark: #70798a;
$skeleton-animation-light: #e4e4e4;
// Spacing
$sp-xs: 4px;
$sp-sm: 8px;
$sp-md: 16px;
$sp-lg: 32px;
$sp-xl: 48px;
$sp-xxl: 64px;
// Font weight
$bold-default: 700; // Roboto font
$bold-heading: 600; // Monserrat font
$default-weight: 400;


