app-home-staff-status {
  .staff-item, .status-item {
    display: flex;
    align-items: center;
    font-size: $paragraph-size;
    cursor: default;
  }

  .staff-item {
    display: inline-flex;
  }

  .status-legend {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .status-item {
      margin-right: 16px;
    }
  }

  .status-item {
    font-style: italic;
    font-size: 12px;
  }
}
