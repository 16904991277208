app-practice-consultation-holder {
  --header-height: #{$header-height};
  --sidebar-width: #{$menu-width};
  --menu-offset: #{$menu-offset};
  --toolbar-offset: #{$menu-width};
  --toolbar-y-offset: #{$header-height};

  app-consultation-progress {
    display: block;
    margin-top: $header-height;
    margin-left: $menu-width;
  }
  app-consultation-steps {
    display: block;
    margin: $sp-md 10px $sp-md 0;
  }
  app-dynamic-consultation-data-collection-holder {
    display: block;
    margin-right: 10px;
  }
  app-consultation-collected-data {
    display: block;
    margin-right: 10px;
  }
  app-messages {
    display: block;
    margin-top: $header-height + $header-offset;
    margin-left: $menu-width + $menu-offset;
  }

  .consultation-container {
    padding-top: $sp-xl;
    margin-left: $menu-width + $menu-offset;
    margin-bottom: 24px;
    background: var(--main-body-background);
  }

  .actions-toolbar-container {
    position: fixed;
    z-index: 102;
    background: var(--header-background);
    border-bottom: 1px solid var(--main-border-color);
    height: $consultation-toolbar-height;
    left: $menu-width;
    top: calc(var(--toolbar-y-offset) - 1px);
    width: calc(100% - var(--toolbar-offset));
    transition: height 0.1s linear;
    .toolbar-items {
      padding: 4px 20px 0 10px;
      height: $consultation-toolbar-height;
      overflow: hidden;
      transition: height 0.1s linear;
      @include flex($align-items: flex-start);
      .toolbar-actions {
        margin-left: auto;
        @include flex($align-items: center);
        .mat-mini-fab {
          width: 28px !important;
          height: 28px !important;
          line-height: 28px !important;
          .mat-button-wrapper {
            line-height: 22px;
            padding: 0;
          }
          mat-icon {
            width: 22px;
            height: 22px;
            line-height: 22px;
            font-size: 22px;
            color: white;
            margin-top: -1px;
          }
        }
        .pet-details, .order-history, .client-details, .client-basket {
          width: unset !important;
          height: unset !important;
          line-height: unset;
          border-radius: 4px;
          padding: 4px 8px;
          &:hover {
            background: rgba(#000, 0.04);
          }
          mat-icon {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            margin-top: -2px;
            margin-right: $sp-xs;
            @include media-breakpoint-down(md) {
              margin-right: 0;
            }
          }
          span.label {
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }
    }
    &.collapsed {
      height: 1px;
      .toolbar-items {
        height: 1px;
        overflow: hidden;
      }
    }
    .actions-toolbar {
      position: relative;
      height: 100%;
      width: 100%;
      .toggle {
        position: absolute;
        bottom: -20px;
        left: calc(50% + var(--menu-offset) / 2);
        transform: translateX(-50%);
        background: var(--header-background);
        border-radius: 50%;
        border: 1px solid var(--main-border-color);
      }
      .consultation-status {
        @include flex($align-items: center);
        mat-icon {
          width: 28px;
          height: 28px;
          font-size: 28px;
          color: var(--main-title-color);
          margin-right: $sp-xs;
          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }
        }
        h5 {
          color: var(--secondary-text-color);
          font-family: 'Roboto', sans-serif;
          font-size: 13px;
          margin: 0;
          margin-block-start: 0;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        p {
          font-size: 12px;
          margin: 0;
          @include media-breakpoint-down(sm) {
            display: none;
          }
          @include media-breakpoint-down(md) {
            font-weight: $bold-default;
            font-size: 13px;
          }

        }
      }
    }
  }
}
