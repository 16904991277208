@mixin skeleton-animation {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: var(--skeleton-gradient);
    animation: skeleton-animation 2s infinite;
    content: '';
  }
}

@keyframes skeleton-animation {
  100% {
    transform: translateX(100%);
  }
}
