$charity-panel-img-height: 65px;
app-charity-tag {
  cursor: pointer;
  .tag {
    &.helpfulhounds {
      background: $helpful-hounds-blue;
    }
    &.our-special-friends {
      background: $our-special-friends-orange;
    }
  }
}

.charity-panel {x
  img {
    max-height: $charity-panel-img-height;
  }
  &.helpfulhounds-panel {
    img {
      max-height: 150px;
    }
  }
}
