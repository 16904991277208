// Third Party Libs
@import '../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../node_modules/bootstrap/scss/bootstrap-grid';
@import '../node_modules/bootstrap/scss/bootstrap-utilities';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import '@angular/cdk/overlay-prebuilt.css';
// Theme variables
@import './theme/default/variables';
@import './theme/dark/variables';
/*-- Themes --*/
@import './theme/light/light-theme';
@import './theme/dark/dark-theme';
@import './theme/default/mixins';
@import './theme/theme';
// Theme Configs
@import './theme/default/general';
@import './theme/default/typography';
// Theme Components
@import './theme/default/components/panel';
@import './theme/default/components/status';
@import './theme/default/components/queue-header';
@import './theme/default/components/skeleton-loaders';
@import './theme/default/components/expanded-details';
@import './theme/default/components/loading-holder';
@import './theme/default/components/queue-appointment-card';
@import './theme/default/components/section-toggle';
@import './theme/default/components/edd-profile';
@import './theme/default/components/pom-products';
@import './theme/default/overrides/material-table';
@import './theme/default/overrides/material-tabs';
@import './theme/default/overrides/material-inputs';
@import './theme/default/overrides/material-buttons';
@import './theme/default/overrides/material-progress-spinner';
@import './theme/default/overrides/material-date-picker';
@import './theme/default/overrides/expand-panels';
@import './theme/default/overrides/material-autocomplete';
@import './theme/default/overrides/material-menu';
@import './theme/default/overrides/material-tooltip';
@import './theme/default/overrides/dialogs';
@import './theme/default/overrides/snackbar';
@import './theme/default/overrides/highcharts';
@import './theme/default/overrides/full-calendar';
@import './theme/default/overrides/tockbox';
@import './theme/default/overrides/prime';
// Components
@import './app/auth/components/login/login.component';
@import './app/auth/components/register/register.component';
@import './app/auth/components/forgot-password/forgot-password.component';
@import 'app/shared/general-shared/components/main-header/main-header.component';
@import './app/history/components/history-holder/history-holder.component';
@import './app/history/components/history-consultations/history-consultations.component';
@import './app/history/components/history-pets/history-pets.component';
@import './app/history/components/history-users/history-users.component';
@import 'app/shared/pet-shared/components/pet-history/pet-history.component';
@import 'app/shared/general-shared/components/pet-profile-image/pet-profile-image.component';
@import 'app/shared/general-shared/components/status/status.component';
@import 'app/shared/general-shared/components/timeline-chart/timeline-chart.component';
@import 'app/shared/general-shared/components/table-search-bar/table-search-bar.component';
@import 'app/shared/general-shared/components/version-update-dialog/version-update-dialog.component';
@import 'app/shared/queue-shared/components/queue-card-queue-details/queue-card-queue-details.component';
@import 'app/shared/general-shared/components/maintenance-dialog/maintenance-dialog.component';
@import 'app/shared/general-shared/components/maintenance-holder/maintenance-holder.component';
@import 'app/shared/consultation-shared/components/venom-code-picker/venom-code-picker.component';
@import 'app/shared/general-shared/components/messages/messages.component';
@import 'app/shared/general-shared/components/theme-selection/theme-selection.component';
@import 'app/shared/general-shared/components/user-order-history/user-order-history.component';
@import 'app/shared/consultation-shared/components/audio-level-indicator/audio-level-indicator.component';
@import 'app/shared/general-shared/components/charity-tag/charity-tag.component';
@import 'app/shared/calendar-shared/components/add-edit-appointment/add-edit-appointment.component';
@import 'app/shared/calendar-shared/components/appointment-picker/appointment-picker.component';
@import './app/home/components/home-staff-status/home-staff-status.component';
@import './app/home/components/home-queue/home-queue.component';
@import './app/home/components/home-holder/home-holder.component';
@import './app/home/components/practice-holder/practice-holder.component';
@import './app/home/components/practice-queue/practice-queue.component';
@import 'app/shared/calendar-shared/components/calendar/calendar.component';
@import './app/calendar/components/calendar-holder/calendar-holder.component';
@import './app/calendar/components/appointment-preview/appointment-preview.component';
@import './app/calendar/components/add-edit-standalone-appointments/add-edit-standalone-appointments.component';
@import 'app/shared/pet-shared/components/treatment-summary/treatment-summary.component';
@import 'app/shared/media-shared/components/media-gallery/media-gallery.component';
@import './app/consultation/components/consultation-holder/consultation-holder.component';
@import 'app/shared/consultation-shared/components/background/background.component';
@import 'app/shared/pet-shared/components/observations/observations.component';
@import 'app/shared/consultation-shared/components/consultation-history/consultation-history.component';
@import './app/consultation/components/video-consultation/video-consultation.component';
@import 'app/shared/queue-shared/components/queue-widget/queue-widget.component';
@import 'app/shared/queue-shared/components/practice-queue-widget/practice-queue-widget.component';
@import './app/consultation/components/treatment-plan-problem-builder/treatment-plan-problem-builder.component';
@import './app/consultation/components/treatment-plan-holder/treatment-plan-holder.component';
@import './app/consultation/components/treatment-plan-products/treatment-plan-products.component';
@import './app/consultation/components/treatment-plan-lab-tests/treatment-plan-lab-tests.component';
@import './app/consultation/components/treatment-plan-resources/treatment-plan-resources.component';
@import './app/consultation/components/treatment-plan-summary/treatment-plan-summary.component';
@import './app/consultation/components/outcomes/outcomes.component';
@import 'app/shared/calendar-shared/components/appointment-type-picker/appointment-type-picker.component';
@import 'app/shared/calendar-shared/components/appointment-type-picker-dropdown/appointment-type-picker-dropdown.component';
@import 'app/shared/general-shared/components/simple-gallery/simple-gallery.component';
@import 'app/shared/consultation-shared/components/product-picker/product-picker.component';
@import 'app/shared/pet-shared/components/pet-history-download/pet-history-download.component';
@import 'app/shared/pet-shared/components/pet-selector/pet-selector.component';
@import './app/practice-consultation/components/practice-consultation-holder/practice-consultation-holder.component';
@import 'app/shared/edd-shared/components/edd-form/edd-form.component';
@import 'app/shared/edd-shared/components/edd-form-profile/edd-form-profile.component';
@import 'app/shared/edd-shared/components/edd-form-point/edd-form-point.component';
@import 'app/shared/edd-shared/components/edd-form-group/edd-form-group.component';
@import 'app/shared/edd-shared/components/edd-form-compact/edd-form-compact.component';
@import 'app/shared/edd-shared/components/edd-form-media/edd-form-media.component';
@import 'app/shared/edd-shared/components/edd-form-signature/edd-form-signature.component';
@import 'app/shared/edd-shared/components/consultation-collected-data/consultation-collected-data.component';
@import 'app/shared/consultation-shared/components/hills-reco-tool/hills-reco-tool.component';
@import 'app/shared/media-shared/components/media-manager-preview/media-manager-preview.component';
@import 'app/shared/media-shared/components/media-manager-input/media-manager-input.component';
@import 'app/shared/calendar-shared/components/appointment-picker-practice/appointment-picker-practice.component';
@import 'app/shared/calendar-shared/components/appointment-listing/appointment-listing.component';
@import 'app/shared/general-shared/components/payment-processing-state/payment-processing-state.component';
@import 'app/consultation/components/consultation-base/consultation-base.component';
@import 'app/shared/general-shared/components/address-selection/address-selection.component';
@import 'app/shared/pet-shared/components/pet-practice-assignment/pet-practice-assignment.component';
@import 'app/rota/components/create-rota/create-rota.component';
@import 'app/rota/components/rota-calendar/rota-calendar.component';
@import 'app/rota/components/rota-preview-shift/rota-preview-shift.component';
@import 'app/rota/components/rota-update-shift/rota-update-shift.component';
@import 'app/shared/general-shared/components/client-registration/client-registration.component';
@import './app/shared/general-shared/components/client-details/client-details.component';
@import './app/shared/general-shared/components/client-basket/client-basket.component';
@import './app/shared/general-shared/components/client-basket-item/client-basket-item.component';
