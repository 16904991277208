app-appointment-type-picker-dropdown {
  .appointment-picker-holder {
    width: 110%;
    padding: 10px;
    background: var(--table-background);
    position: absolute;
    top: 45px;
    z-index: 999;
    border: 1px solid;
    border-color: var(--main-border-color);
    margin-bottom: 20px;
    max-height: 250px;
    overflow: auto;
  }
  .input-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 997;
  }

  .no-results p {
    margin-bottom: 0;
  }

  .inputs-holder {
    position: relative;
  }

  .category-item {
    cursor: pointer;
  }

  .appointment-picker-holder .list-unstyled {
    margin-bottom: 0;
    padding-left: 10px;
  }

  .category-label {
    font-size: 13px;
    font-weight: $bold-default;
    color: var(--main-title-color);
    position: relative;
    top: -6px;
    text-transform: capitalize;
  }

  .root-list {
    padding-left: 0 !important;
  }

  .appointment-type {
    font-size: 13px;
    padding-left: 15px;
    font-style: italic;
    font-weight: normal;
    cursor: pointer;
  }
}
