$missing-image-icon-size: 84px;

app-client-details {
  position: relative;

  .inline-icon {
    position: relative;
    top: -10px;

    .mat-icon {
      position: relative;
      top: 7px;
    }
  }

  .phone_number_holder, .full_name_holder {
    position: relative;
    top: -5px;

    .phone_label, .full_name_label {
      margin-top: 9px;
      display: inline-block;
    }

    .mat-flat-button {
      margin-bottom: 5px;
    }

    .edit-btn {
      position: relative;
      top: 5px;
      margin-left: 10px;
    }
  }

  .expand-trigger {
    cursor: pointer;
  }

  .expand-toggle {
    float: right;

    .mat-icon {
      width: 30px;
      height: 30px;
      font-size: 30px;
      color: var(--main-title-color);
    }
  }

  .address-details {
    position: relative;
    border-radius: $button-border-radius;
    background: var(--address-panel-background);
    margin-bottom: 20px;
    padding: 10px;
  }

  .address-form, .pet-details-form {
    margin-top: $sp-sm;
    .p-inputtext-sm, .p-dropdown, .p-checkbox, .p-multiselect, .p-inputnumber {
      margin-bottom: 5px;
      width: 100%;
    }
    label {
      display: block;
      &.warning {
        color: var(--warning-background);
        margin-bottom: $sp-sm;
      }
    }
  }

  .checkbox-label {
    position: relative;
    top: -6px;
  }

  .main-holder {
    position: relative;
  }

  .client-details-header {
    h3 {
      display: inline-block;
      padding-left: 100px;
    }
  }

  .client-avatar {
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    border: 2px solid var(--main-border-color);

    img {
      width: auto;
      height: 100%;
    }

    .mat-icon {
      font-size: $missing-image-icon-size;
      width: $missing-image-icon-size;
      height: $missing-image-icon-size;
    }
  }

  .pet-profile {
    position: relative;
    height: calc(100% - 20px);
    margin-bottom: 20px;
    border-radius: $button-border-radius;
    background: var(--address-panel-background);
    padding: 10px;

    .pet-avatar-holder {
      width: 64px;
      display: inline-block;
      margin-right: 30px;
    }

    .pet-details-holder {
      display: inline-block;
      width: calc(100% - 94px);
      vertical-align: top;

      p {
        margin-bottom: 0;
      }
    }
  }

  .btns-holder {
    position: absolute;
    top: 0;
    right: 5px;
  }
}
