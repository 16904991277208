$fab-size: 56px;

app-simple-gallery {
  .gallery-container {
    position: relative;
    img {
      max-width: 50vw;
      max-height: 50vh;
      @include media-breakpoint-up(md) {
        max-width: 75vw;
        max-height: 75vh;
      }
    }
    button {
      &:focus {
        outline: none;
      }
      &.back {
        position: absolute;
        left: calc(-56px / 2);
        top: 50%;
        transform: translateY(-50%);
      }
      &.forward {
        position: absolute;
        right: calc(-56px / 2);
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .close-icon {
      position: absolute;
      right: 2px;
      top:2px;
      cursor: pointer;
      color: white;
      transition: all 0.5s ease;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
}
