.section-header {
  position: relative;
  cursor: pointer;
  background: var(--header-background);
  padding: 7px 5px;
  border-left: 1px solid var(--main-border-color);
  border-right: 1px solid var(--main-border-color);
  border-bottom: 1px solid var(--consultation-section-background);
  user-select: none;

  h2 {
    position: relative;
    top: 3px;
  }

  &.first {
    border-top-left-radius: $panel-border-radius;
    border-top-right-radius: $panel-border-radius;
    border-top: 1px solid var(--main-border-color);
  }

  &.last {
    border-bottom: none;
  }

  &.last.collapsed {
    border-bottom-left-radius: $panel-border-radius;
    border-bottom-right-radius: $panel-border-radius;
    border-bottom: 1px solid var(--main-border-color);
  }

  .toggle-icon {
    color: var(--main-title-color);
    position: absolute;
    top: 7px;
    right: 3px;
  }
}

.section-content {
  height: 0;
  overflow: hidden;
  padding: 0;
  background: var(--consultation-section-background);
  border-left: 1px solid var(--main-border-color);
  border-right: 1px solid var(--main-border-color);

  &.expanded {
    height: auto;
    padding: 10px;

    &.last {
      border-bottom-left-radius: $panel-border-radius;
      border-bottom-right-radius: $panel-border-radius;
      border-bottom: 1px solid var(--main-border-color);
    }
  }
}
