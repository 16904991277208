$consultation-toolbar-height: 54px;
app-consultation-remote-template, app-consultation-update-template, app-consultation-practice-template {
  --header-height: #{$header-height};
  --sidebar-width: #{$menu-width};
  --menu-offset: #{$menu-offset};
  --toolbar-offset: #{$menu-width};
  --toolbar-y-offset: #{$header-height};

  @mixin loading-overlay {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    z-index: 1;
    background: var(--overlay-background);
  }

  .saving {
    pointer-events: none;
  }
  app-consultation-progress {
    display: block;
    margin-top: $header-height;
    margin-left: $menu-width;
  }
  app-messages {
    display: block;
    margin-top: $header-height + $header-offset;
    margin-left: $menu-width + $menu-offset;
  }
  .consultation-container {
    padding-top: $sp-xl;
    margin-left: $menu-width + $menu-offset;
    margin-right: 10px;
    margin-bottom: 24px;
    background: var(--main-body-background);
    transition: padding-top 0.1s linear;

    &.collapsed {
      padding-top: 0;
    }
  }

  .joii-panel.no-payment, .joii-panel.basket-loading {
    width: 50%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    min-height: 250px;
  }

    .loading-overlay {
    @include loading-overlay;
    @include flex($align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .actions-toolbar-container {
    position: fixed;
    z-index: 102;
    background: var(--header-background);
    border-bottom: 1px solid var(--main-border-color);
    height: $consultation-toolbar-height;
    left: $menu-width;
    top: calc(var(--toolbar-y-offset) - 1px);
    width: calc(100% - var(--toolbar-offset));
    transition: height 0.1s linear;
    .toolbar-items {
      padding: 4px 20px 0 10px;
      height: $consultation-toolbar-height;
      overflow: hidden;
      transition: height 0.1s linear;
      @include flex($align-items: flex-start);
      .toolbar-actions {
        margin-left: auto;
        @include flex($align-items: center);
        .mat-mini-fab {
          width: 28px !important;
          height: 28px !important;
          line-height: 28px !important;
          .mat-button-wrapper {
            line-height: 22px;
            padding: 0;
          }
          mat-icon {
            width: 22px;
            height: 22px;
            line-height: 22px;
            font-size: 22px;
            color: white;
            margin-top: -1px;
          }
        }
        .pet-details, .order-history, .client-details, .client-basket {
          width: unset !important;
          height: unset !important;
          line-height: unset;
          border-radius: 4px;
          padding: 4px 8px;
          &:hover {
            background: rgba(#000, 0.04);
          }
          mat-icon {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            margin-top: -2px;
            margin-right: $sp-xs;
            @include media-breakpoint-down(md) {
              margin-right: 0;
            }
          }
          span.label {
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
          &.disabled {
            opacity: 0.5;
          }
          i {
            margin-left: $sp-xs;
          }
        }
        button.status {
          margin-right: $sp-sm;
        }
        &.practice {
          .pet-details, .order-history, .client-details {
            mat-icon {
              @include media-breakpoint-down(md) {
                margin-right: 0;
              }
            }
            span.label {
              @include media-breakpoint-down(lg) {
                display: none;
              }
            }
          }
          .handover-action, .start-action {
            mat-icon {
              margin-right: $sp-xs;
            }
            span.label {
              @include media-breakpoint-down(lg) {
                display: none;
              }
            }
          }
        }
      }
    }
    &.collapsed {
      height: 1px;
      .toolbar-items {
        height: 1px;
        overflow: hidden;
      }
    }
    .actions-toolbar {
      position: relative;
      height: 100%;
      width: 100%;
      .toggle {
        position: absolute;
        bottom: -20px;
        left: calc(50% + var(--menu-offset) / 2);
        transform: translateX(-50%);
        background: var(--header-background);
        border-radius: 50%;
        border: 1px solid var(--main-border-color);
        mat-icon {
          color: var(--main-text-color)
        }
      }
      .consultation-status {
        @include flex($align-items: center);
        mat-icon {
          width: 28px;
          height: 28px;
          font-size: 28px;
          color: var(--main-title-color);
          margin-right: $sp-xs;
          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }
        }
        h5 {
          color: var(--secondary-text-color);
          font-family: 'Roboto', sans-serif;
          font-size: 13px;
          margin: 0;
          margin-block-start: 0;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        p {
          font-size: 12px;
          margin: 0;
          @include media-breakpoint-down(sm) {
            display: none;
          }
          @include media-breakpoint-down(md) {
            font-weight: $bold-default;
            font-size: 13px;
          }

        }
        &.practice {
          mat-icon {
            @include media-breakpoint-down(md) {
              margin-right: 0;
            }
          }
          h5 {
            @include media-breakpoint-down(lg) {
              display: none;
            }
          }
          p {
            @include media-breakpoint-down(sm) {
              display: none;
            }
            @include media-breakpoint-down(lg) {
              font-weight: $bold-default;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .save-loading-holder {
    background: rgba(black, 0.3);
    position: fixed;
    border-radius: 3px;
    padding: $sp-md $sp-xl;
    width: calc(100vw - var(--sidebar-width));
    height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: var(--header-height);
    left: var(--sidebar-width);
    pointer-events: none;
    z-index: 1000;
  }

  .no-payment-container {
    width: 100%;
    text-align: center;
    margin-top: $sp-md;
    h1 {
      margin-bottom: $sp-md;
    }
  }
}

.terminate-overlay-container {
  background: var(--panel-background);
  border-color: var(--main-border-color);
  max-width: 800px;
  width: 70vw;
  padding: 8px;
  margin-top: 4px;
  mat-form-field {
    width: 100%;
  }
  text-area {
    height: 150px;
  }
  h6 {
    color: var(--main-text-color);
  }
  .mat-form-field-label {
    color: var(--main-text-color);
  }
  .mat-form-field.mat-focused {
    .mat-form-field-label {
      color: var(--main-text-color);
    }
  }
  .form-control {
    width: 100%;
    text-overflow: ellipsis;
    padding-left: 8px;
    margin-bottom: 8px;
    background: none;
    border: none;
    border-radius: 0;
    font-size: 14px;
    &::placeholder {
      color: var(--main-text-color);
    }
  }
  .input-meta {
    width: 100%;
    display: inline-block;
    text-align: right;
    position: relative;
    margin-top: -$sp-md;
    color: var(--main-text-color);
    font-weight: 500;
  }
  mat-error {
    margin-bottom: $sp-xs;
  }
}

.consultation-completion-retry-dialog {
  * {
    text-align: center;
  }
  //h2 {
  //  font-size:
  //}
  p, strong {
    margin-top: $sp-md;
    font-size: 16px;
  }
  .retry-completion-actions {
    width: 100%;
    @include flex($justify-content: center, $align-items: center);
  }
}
