app-edd-form-point {
  .sub-label {
    padding: 10px 0 0 20px;
    margin-bottom: 0;
    font-weight: $bold-default;
    display: block;
  }

  label {
    font-weight: 400;
    display: block;
    margin-bottom: $sp-sm;
  }

  .error-label {
    color: var(--error-background);
  }

  .form-control {
    margin-left: 0;
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid transparent;
    border-color: var(--main-border-color);
  }

  .date-control {
    margin-left: 0;
    margin-top: 8px;
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid transparent;
  }
  .field-checkbox, .field-radiobutton {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  .checkbox-label, .radio-label {
    margin-left: $sp-sm;
    margin-bottom: 0;
  }

  .radio-label-container {
    display: flex;
    align-items: center;
    margin-bottom: $sp-md;
    height: 44px;
    label {
      margin: 2px 0 0 0;
    }
    button {
      margin-left: $sp-sm;
    }
  }

  .sub-input-group .form-control {
    margin-top: 0;
  }

  .sub-input-group {
    margin-top: $sp-md;
    margin-left: $sp-md;
    .sub-label {
      margin: 0;
      padding: 0;
    }
  }
  .postcode-input {
    margin-right: 20px;
  }

  .postcode-find-btn {
    margin-top: $sp-xs;
  }

  .mat-datepicker-toggle {
    position: absolute;
    top: -24px;
    right: 100%;
  }

  .mat-form-field-label-wrapper {
    padding-top: 0;
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
  }

  .multi-select-holder {
    margin-left: 0;
    margin-top: 10px;
    border-bottom: 1px solid;
    border-color: var(--main-border-color);
    border-radius: 0;
    overflow: hidden;
  }

  .postcode-lookup .form-control, .postcode-lookup button {
    display: inline-block;
  }

  .select-question, .control-holder {
    display: flex;
    align-items: center;
    position: relative;
    .document-icon {
      margin: 0 $sp-sm 0 0;
    }
  }

  .postcode-search {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    p-autoComplete {
      flex-grow: 1;
      margin-right: $sp-sm;
    }
    button {
      min-width: 135px;
    }
  }

  .address-group-inline {
    display: inline-block;
    margin-top: $sp-sm;
    margin-left: $sp-md;
    width: calc(100% - 16px);
    label {
      font-weight: 500;
      font-size: 13px;
    }
    input {
      margin-top: 0;
      font-size: 13px;
    }
  }

  p-inputnumber, .p-inputtext,  {
    width: 100%;
    > span {
      width: 100%
    }
  }

  p-inputmask {
    width: 100%;
  }

  p-dropdown {
    width: 100%;
    > .p-dropdown {
      width: 100%;
    }
  }

  p-multiselect {
    width: 100%;
    > .p-multiselect {
      width: 100%;
    }
  }

  .hint {
    color: var(--secondary-text-color);
  }

  .media-image {
    width: 33vw;
  }

  app-media-manager-preview {
    display: block;
    margin-top: $sp-xs;
  }

  .signature-preview {
    position: relative;

    .clear-signature-btn {
      position: absolute;
      top: 0;
      right: 0;

      span {
        color: var(--error-background);
      }
    }

    .signature-image {
      width: 255px;
      height: 119px;
      position: relative;
      background: var(--edd-signature-background);
      margin-bottom: 10px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .radio-label {
    display: block;
    font-weight: 400;
  }

  .radio-holder {
    display: block;
  }

  .radio-group-holder {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    align-items: flex-start;
  }

.p-image {
    img {
      max-width: 33vw;
    }
  }
}



