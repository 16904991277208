app-forgot-password {
  app-messages {
    display: block;
    margin: 16px;
  }
  .forgotten-holder {
    width: 100%;
    height: 100%;
    background: var(--login-background);
    position: absolute;

    header {
      text-align: center;

      .main-logo {
        display: inline-block;
        max-width: 200px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: auto;
        }
      }

      h1 {
        margin-bottom: 20px;
      }
    }

    .navigate-back {
      position: absolute;
      left: $sp-md;
      top: $sp-md;
    }

    .forgotten-form {
      max-width: $login-form-max-width;
      margin: 50px auto;

      .mat-form-field {
        width: 100%;
        display: inline-block;
      }

      .marketing-container {
        float: right;
      }
      .actions-container {
        width: 100%;
        @include flex();

        .p-button {
          padding: 0.75rem 1.20rem;
        }

        p-button {
          width: calc(50% - ($sp-sm  / 2));
        }
        p-button:first-child {
          margin-right: $sp-sm;
        }

        p-button {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
