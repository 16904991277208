$message-info-color: royalblue;
$message-error-color: $bright-red;
$message-warning-color: $bright-orange;
$message-success-color: seagreen;

app-messages {
  .banner {
    margin-bottom: 8px;
    padding: 8px 10px;
    border-radius: $panel-border-radius;
    border: 1px solid;
    background: var(--panel-background);
    border-color: var(--main-border-color);
    @include flex($align-items: center, $justify-content: flex-start);
    &-status {
      &-info, &-announcement {
        border-bottom: 4px solid $message-info-color;
      }
      &-success {
        border-bottom: 4px solid $message-success-color;
      }
      &-warn {
        border-bottom: 4px solid $message-warning-color;
      }
      &-error {
        border-bottom: 4px solid $message-error-color;
      }
    }
    mat-icon {
      margin-right: 8px;
    }
  }

}
