$afi-image-height: 35px;
$asda-image-height: 16px;
$purely-pets-image-height: 50px;
$waggel-image-height: 24px;

app-treatment-summary {
  .treatment-heading {
    @include flex($justify-content: space-between);
    text-align: right;
    h5 {
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
    }
    .flex-row {
      justify-content: flex-end;
    }
    app-charity-tag {
      display: block;
    }
    .sent-tag {
      margin-left: $sp-xs;
    }
  }

  .loading-holder {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 5px;
  }

  .extended-data-holder {
    margin-bottom: 10px;
    overflow: hidden;
  }

  .extended-data-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    clear: both;
    overflow: hidden;
  }

  .group-header {
    padding-left: 15px;
    background: var(--expand-panel-background);
    border-bottom: 1px solid var(--pet-profile-border-color);

    &.last-group {
      border-bottom: none;
    }

    strong {
      position: relative;
      top: -3px;
    }
  }

  .toggle-trigger {
    position: relative;
    top: 3px;
  }

  .toggle-title {
    position: relative;
    top: -4px;
  }

  .treatment-content {
    text-align: left;
  }

  .data-point {
    padding: 10px;
  }

  .signature-holder {
    width: 255px;
    height: 119px;
    display: block;
    background: var(--edd-signature-background);

    img {
      width: 100%;
      height: auto;
    }
  }

  .provider-logo {
    &.afi {
      max-height: $afi-image-height;
    }
    &.asda {
      max-height: $asda-image-height;
      margin-top: -$sp-xs;
    }
    &.purely_pets {
      max-height: $purely-pets-image-height;
    }
    &.waggel {
      max-height: $waggel-image-height;
      margin-left: $sp-xs;
      margin-top: -$sp-xs;
    }
  }

  .treatment-summary-table.mat-table {
    width: 100%;
    margin-bottom: 1rem;
    tr {
      th.mat-header-cell {
        background: var(--table-header-background);
        color: var(--main-text-color);
      }
      td.mat-cell {
        background: var(--table-background);
        border-top: none;
        border-bottom: 1px solid;
        border-color: var(--main-border-color);
      }
      td.mat-cell.purchased {
        color: var(--success-background);

        mat-icon {
          color: var(--main-text-color);
          font-size: 18px;
          width: 18px;
          height: 18px;
          margin-right: 4px;
          &:hover {
            cursor: default;
          }
        }
        span {
          color: var(--success-background);
        }
      }
      td.mat-cell.not-purchased {
        span {
          color: var(--error-background);
        }
      }
      td .product-purchased {
        display: flex;
        align-items: center;
      }
    }
  }

  .images-container, .videos-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    grid-column-gap: 24px;
    margin: 1em 0;
    &.image-section, &.video-section {
      margin-right: $sp-lg;
    }
    .image-scroll-container, .video-scroll-container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      img {
        position: relative;
        max-height: 120px;
        background-size: contain;
        height: auto;
      }
      video {
        max-height: 120px;
        max-width: 150px;
        width: 100%;
        background-size: contain;
        // @ToDo: Needs a specific color
        background: #e6e6e6;
      }
    }
    .image-container, .video-container {
      margin-right: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // @ToDo: Needs a specific color
      background: #e6e6e6;
    }
    .image-overlay, .video-overlay {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(100%);
      background: transparent;

      mat-icon {
        font-size: 32px;
        color: transparent;
        position: absolute;
        left: calc(50% - 5px);
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        // @ToDo: Needs a specific color
        background: rgba(black, 0.4);
        cursor: pointer;
        mat-icon {
          // @ToDo: Needs a specific color
          color: white;
        }
      }

    }
    .body-map-text {
      text-transform: uppercase;
    }
  }

  .resources {
    @include flex($flex-direction: column);
    margin-bottom: 1rem;

    .resource-item {
      @include flex($align-items: center);
      display: inline-flex;
      font-size: 12px;
      line-height: 12px;
      color: var(--main-title-color);
      font-weight: $bold-default;

      &:hover {
        color: var(--main-title-color-hover);
        text-decoration: underline;
      }
      .mat-icon {
        font-size: 24px;
      }
      .pdf-icon {
        color: var(--error-background);
      }

      .image-icon {
        // @ToDo: Needs a specific color
        color: rgba(0,0,0,.12);
      }

      .launch-icon {
        font-size: 13px;
        line-height: 13px;
        width: 14px;
        height:14px;
      }
    }
  }

  .response-grid {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(350px, 350px) );
    column-gap: 8px;
    row-gap: 16px;
    margin-bottom: $sp-sm;
  }

  .response-card {
    padding: 0 0 4px 0;
    border: 1px solid;
    border-top: 1px solid;
    border-color: var(--main-border-color);
    border-top-color: var(--main-title-color);
    background: var(--panel-background);
    border-radius: 10px;
    .response-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: var(--main-title-color);
      padding: 4px;
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        color: $white;

      }
    }
    .response-content {
      padding: 8px;
      position: relative;
      strong.header {
        // question
        margin: 4px 0 0;
        font-size: 14px;
        color: var(--main-text-color);
      }
      p, strong {
        margin: 4px 0 0;
        font-size: 14px;
      }
    }
  }
  .label-printing-actions {
    display: flex;
    mat-icon {
      margin: 0 $sp-xs;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .label-printing-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .prescriptions-items {
    width: 100%;
    margin-top: $sp-md;
    text-align: left;
    ul {
      list-style-type: none;
      margin-block-start: 0;
      padding-inline-start: 0;
      li {
        padding: $sp-md;
        border-radius: 8px;
        border: 1px solid var(--main-border-color);
        margin-bottom: $sp-xs;
        display: flex;
        justify-content: space-between;
        align-items: center;
        mat-icon {
          position: relative;
          top: 2px;
          margin-left: $sp-sm;
          cursor: pointer;

        }
      }
    }
    button {
      display: inline-block;
      margin: 0 $sp-xs;
    }
    .actions {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
