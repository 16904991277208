app-history-consultations {
  .expand-row {
    &:hover {
      cursor: pointer;
    }
  }
  .expanded-details {
    .note-grid-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: left;
    }
    .note-card {
      background: var(--panel-background);
      width: 200px;
      padding: $sp-md;
      border-radius: 10px;
      scale: 1;
      border: 2px solid;
      border-color: transparent;
      transition: all 0.1s ease-in;
      margin-right: $sp-md;
      margin-bottom: $sp-md;
      p {
        margin: 0;
      }
      &:hover {
        cursor: pointer;
        scale: 1.05;
        border-color: var(--main-border-color);
      }
      &.selected {
        border-color: var(--main-title-color);
        &:hover {
          cursor: pointer;
          scale: 1;
        }
      }
    }
  }
  .sessions-btn {
    mat-icon {
      margin-right: 4px;
    }
  }
  .info-icon {
    color: var(--main-title-color);
    &:hover {
      color: var(--main-title-color-hover);
    }
  }
}
