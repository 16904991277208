app-practice-holder {
  --header-height: #{$header-height};
  --sidebar-width: #{$menu-width};
  .page-holder {
    position: relative;
    &.checking-consult {
      filter: blur(4px);
    }
  }
}
