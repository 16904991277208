.mat-menu-panel {
  background: var(--panel-background);
  .mat-icon-no-color {
    color: var(--main-text-color);
  }
  .mat-menu-submenu-icon {
    color: var(--main-text-color);
  }
}

.mat-menu-item {
  color: var(--main-text-color);
  * {
    color: var(--main-text-color);
  }
}
