app-create-rota {
  form, .staff-members-container, .staff-member, .shift {
    display: flex;
    flex-direction: column;
    row-gap: $sp-sm;
    column-gap: $sp-sm;

    .p-inputtextarea {
      height: 64px;
    }
  }
  .p-calendar .p-inputtext {
    width: unset;
  }
  .p-inputtext, .p-inputtextarea, .p-calendar, .p-autocomplete, .p-multiselect {
    width: 100%;
  }
  .staff-members-heading {
    display: flex;
    align-items: center;
    h6 {
      margin-bottom: 0px;
    }
  }
  .add-staff-member-icon {
    margin-left: $sp-sm;
    color: var(--main-title-color);
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .add-staff-member-btn {
    margin: $sp-sm $sp-sm $sp-sm auto;
  }
  .time-container {
    display: flex;
    align-items: center;
    label {
      padding-right: $sp-xs;
    }
    p-calendar {
      margin-left: $sp-sm;
      width: 100%;
    }
  }

  p-listbox {
    margin-bottom: $sp-md;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: $sp-xs;
    label {
      padding-right: $sp-xs;
    }
    i {
      padding-right: $sp-sm;
    }
  }
  .p-tag-icon {
    margin-left: $sp-xs;
    color: white;
  }
  .p-tag {
    background: var(--main-title-color);
    cursor: pointer;
  }
  p-scrollPanel {
    height: 80vh;
  }
  .rotaScroll {
    .p-scrollpanel-bar {
      background: var(--main-title-color);
    }
  }

  .p-accordion .p-accordion-header .p-accordion-header-link:focus {
    box-shadow: none !important;
  }

  .accordion-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      * {
        margin-left: 2px;
      }
    }
  }

  .p-button-raised {
    color: unset !important;
  }

  button.p-button .p-button-icon {
    color: unset !important;
  }
    .p-button.p-button-icon-only.p-button-rounded {
      height: 1.5rem !important;
      width: 1.5rem !important;
      .pi {
        font-size: 0.7rem !important;
      }
    }

  .staff-member {
    .p-button.p-button-icon-only.p-button-rounded {
      height: 2rem !important;
      width: 2rem !important;
      .pi {
        font-size: 0.8rem !important;
      }
    }
  }


  .shift-header {
    .shift-header-icon {
      margin-right: $sp-xs;
    }
    .shift-header-label {
      font-weight: bold;
    }
  }

  .shift-actions {
    margin-top: $sp-sm;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .p-button-icon-only {
      margin-left: $sp-xs;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .p-button {
      margin-left: $sp-sm;
    }
  }

  p-fieldset {
    display: block;
    margin: $sp-sm 0;
  }

  legend {
    width: unset;
    padding: 8px;
    float: unset;
    margin-bottom: $sp-sm;
    font-size: unset;
    margin-left: $sp-sm;
    a {
      padding: 0.5rem !important;
      text-decoration: none !important;
    }
  }

  .p-fieldset-toggler {
    display: none !important;
  }

  p-splitButton {
    margin-top: 24px;
    display: block;
  }
}

