app-version-update-dialog {
  display: block;
  height: 100%;

  .version-container {
    padding: 1rem;
    min-height: calc(286px + 1rem);
    @include flex($flex-direction: column, $justify-content: space-between, $align-items: center);
  }

  .title {
    padding-bottom: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 36px;
  }

  .title mat-icon {
    font-size: 32px;
    height: 32px;
    width: 32px;
    margin-right: 8px;
  }

  p {
    font-size: 20px;
    text-align: center;
  }
}
