
app-appointment-preview {
  .appointment-preview-container {
    min-width: 33vw;
    max-width: 600px;
  }

  .appointment-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    * {
      margin: 0px $sp-xs;
    }
  }

  .action-bar {
    position: relative;

    .action-icons {
      // @ToDo: Needs a specific color
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: darkgrey;
      position: absolute;
      right: $sp-xs;
      top: 0;
      button {
        margin: 0 $sp-xs;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .header {
    @include flex($justify-content: space-between, $flex-direction: column);
    margin-bottom: $sp-sm;
  }

  h5, h6 {
    @include remove-block-padding;
    text-transform: capitalize;
  }
  h5 {
    margin-right: $sp-lg;
    margin-bottom: $sp-xs;
  }

  h6 {
    color: darkgrey;
    font-size: 14px;
  }

  p {
    @include remove-block-padding;
  }

  .date-container {
    flex-shrink: 0; // For parent.
    width: 100%;
    margin-top: $sp-xs;
    @include flex($align-items: flex-end, $justify-content: space-between);
    .date {
      @include flex($align-items: center);
    }
    mat-icon {
      // @ToDo: Needs a specific color
      color: darkgrey;
      margin-right: $sp-xs;
      font-size: 18px;
      height: 18px;
      width: 18px;
      line-height: 16px;
    }
    h6 {
      text-wrap: none;
      white-space: nowrap;
      line-height: 20px;
      margin-left: 4px;
    }
    .status {
      padding: 4px 8px;
      border-radius: 4px;
      cursor: default;
      &.confirmed, &.joined-triage, &.initiated {
        background: rgba($appointment-confirmed-color, 1);;
      }
      &.completed {
        background: rgba($appointment-completed-color, 1);;
      }
      &.pending, &.unconfirmed {
        background: rgba($appointment-pending-color, 1);;
      }
      &.missed, &.denied {
        background: rgba($appointment-denied-color, 1);;
      }
    }
  }

  .email {
    color: var(--secondary-title-color);
  }

  .pet-profile {
    @include flex;
    padding: $sp-xs;
    background: var(--main-panel-color);
    border-radius: 10px;

    .details {
      padding-left: $header-offset;
    }
    .history-icon, .note-icon {
      align-self: center;
      margin-left: auto;
      mat-icon {
        color: darkgrey;
      }
    }

    .history-icon {
      margin-left: 10px;
    }
    &:hover {
      // @ToDo: Needs a specific color
      background: rgba(black, 0.05);
      cursor: pointer;
      .history-icon {
        // @ToDo: Needs a specific color
        /*color: darken($main-title-color, 5%);*/
      }
    }
    app-charity-tag {
      display: block;
      margin: $sp-xs 0;
    }
  }
}

.appointment-preview-cancel-actions {
  width: 100%;
  margin-top: $sp-lg;
  display: flex;
  justify-content: center;
  button {
    margin: 0 $sp-md;
  }
}

.cancel-appointment-dialog {
  .mat-dialog-content {
    width: 600px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
