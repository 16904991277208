app-media-gallery {
  .gallery-holder {
    width: 100%;
    max-width: 900px;
    height: auto;
    margin: 0 auto 0 auto;
    overflow-y: auto;

    h1 {
      font-size: 16px;
      margin: 10px;
    }
  }

  .mat-fab.mat-primary {
    background: transparent;
  }

  .thumbnail-gallery {
    width: 100%;
    min-height: 64px;
    text-align: left;
    position: relative;
  }

  .thumbnail-slider {
    position: absolute;
    margin: 8px auto;
    padding-left: $sp-md;
    transition: left 300ms ease-in;
  }

  .images-holder {
    position: relative;
    text-align: center;
    overflow-x: hidden;
    background: var(--table-background);
  }

  .images-holder .image {
    display: inline-block;
  }

  .images-holder .image img, .images-holder video {
    width: auto;
    height: auto;
    max-width: 600px;
    max-height: 500px;
  }

  .image-thumbnail {
    display: inline-block;
    background: black;
    max-width: 90px;
    min-height: 50px;
    max-height: 50px;
    overflow: hidden;
    margin-right: 5px;
    cursor: pointer;
    text-align: center;
  }

  .image-thumbnail img, .image-thumbnail video {
    max-width: 90px;
    min-height: 50px;
    max-height: 50px;
    min-width: 50px;
    object-fit: contain;
    opacity: 40%;
  }

  .image-thumbnail:hover img, .image-thumbnail:hover video {
    opacity: 60%;
  }

  .image-thumbnail.active img, .image-thumbnail.active video {
    opacity: 100%;
  }

  .image-slider {
    position: relative;
    transition: left 300ms ease-in;
  }

  .main-navigation {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 0;
    z-index: 999;
  }

  .navigation-btn {
    // @ToDo: Needs a specific color
    color: #5c5c5c;
  }

  .back-btn, .next-btn {
    font-size: 50px;
    width: 50px;
    height: 50px;
    // @ToDo: Needs a specific color
    background: var(--main-title-color);
    color: white;
    display: block;
    position: absolute;
    border-radius: 50%;
    transition: all 100ms linear;
    transform-origin: center;
    &:focus {
      font-size: 50px;
      width: 50px;
      height: 50px;
      background: darken($pale-blue, 5%);
    }
    &:hover {
      font-size: 50px;
      width: 50px;
      height: 50px;
      background: darken($pale-blue, 5%);
      cursor: pointer;
    }
  }

  .back-btn {
    left: 20px;
  }

  .next-btn {
    right: 20px;
  }

  .thumb-back-btn, .thumb-next-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 5px;
    z-index: 999;
  }

  .thumb-back-btn .mat-icon, .thumb-next-btn .mat-icon {
    position: relative;
    top: -9px;
  }

  .thumb-back-btn {
    left: 5px;
  }

  .thumb-next-btn {
    right: 5px;
  }

  .label {
    text-align: center;
  }

}
