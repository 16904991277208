$pet-profile-size: (width: 60px, height: 60px, border-radius: 50%);

$asda-icon-size: (
  width: auto,
  height: 25%,
  border-radius: 2px,
  left: 50%,
  top: 90%
);

$afi-icon-size: (
  width: auto,
  height: 35%,
  border-radius: 0,
  left: 50%,
  top: 90%
);

$afi-queue-icon-size: (
  width: auto,
  height: 45%,
  border-radius: 0,
  left: 85%,
  top: 85%
);

$asda-queue-icon-size: (
  width: auto,
  height: 35%,
  border-radius: 2px,
  left: 95%,
  top: 85%
);

$purely-pets-icon-size: (
  width: auto,
  height: 50%,
  border-radius: 0,
  left: 50%,
  top: 85%
);

$purely-pets-queue-icon-size: (
  width: auto,
  height: 65%,
  border-radius: 0,
  left: 85%,
  top: 75%
);

$waggel-icon-size: (
  width: auto,
  height: 35%,
  border-radius: 10px,
  left: 50%,
  top: 90%
);

$waggel-queue-icon-size: (
  width: auto,
  height: 50%,
  border-radius: 10px,
  left: 85%,
  top: 85%
);

app-pet-profile-image {
  --pet-profile-max-size: unset;
  position: relative;
  .image-container {
    position: relative;
  }
  .pet-image {
    width: map-get($pet-profile-size, width);
    height: map-get($pet-profile-size, height);
    border-radius: map-get($pet-profile-size, border-radius);
    border: 2px solid var(--pet-profile-border-color);
    max-height: var(--pet-profile-max-size);
    max-width: var(--pet-profile-max-size);
  }
  .overlayed {
    position: absolute;
    bottom: -4px;
    right: -4px;
  }
  .insurance {
    position: absolute;
    &.afi {
      width: map-get($afi-icon-size, width);
      height: map-get($afi-icon-size, height);
      top: map-get($afi-icon-size, top);
      left: map-get($afi-icon-size, left);
      transform: translate(-50%, -50%);
      border-radius: map-get($afi-icon-size, border-radius);
      &.queue-img {
        width: map-get($afi-queue-icon-size, width);
        height: map-get($afi-queue-icon-size, height);
        top: map-get($afi-queue-icon-size, top);
        left: map-get($afi-queue-icon-size, left);
      }
    }
    &.purely-pets {
      width: map-get($purely-pets-icon-size, width);
      height: map-get($purely-pets-icon-size, height);
      top: map-get($purely-pets-icon-size, top);
      left: map-get($purely-pets-icon-size, left);
      transform: translate(-50%, -50%);
      border-radius: map-get($purely-pets-icon-size, border-radius);
      &.queue-img {
        width: map-get($purely-pets-queue-icon-size, width);
        height: map-get($purely-pets-queue-icon-size, height);
        top: map-get($purely-pets-queue-icon-size, top);
        left: map-get($purely-pets-queue-icon-size, left);
      }
    }
    &.asda {
      width: map-get($asda-icon-size, width);
      height: map-get($asda-icon-size, height);
      top: map-get($asda-icon-size, top);
      left: map-get($asda-icon-size, left);
      border-radius: map-get($asda-icon-size, border-radius);
      transform: translate(-50%, -50%);
      border: 1px solid;
      background-color: var(--main-panel-color);
      border-color: var(--main-border-color);
      padding: 2px;
      &.queue-img {
        width: map-get($asda-queue-icon-size, width);
        height: map-get($asda-queue-icon-size, height);
        top: map-get($asda-queue-icon-size, top);
        left: map-get($asda-queue-icon-size, left);
        border-radius: map-get($asda-queue-icon-size, border-radius);
      }
    }
    &.waggel {
      width: map-get($waggel-icon-size, width);
      height: map-get($waggel-icon-size, height);
      top: map-get($waggel-icon-size, top);
      left: map-get($waggel-icon-size, left);
      border-radius: map-get($waggel-icon-size, border-radius);
      transform: translate(-50%, -50%);
      border: 1px solid;
      background-color: var(--main-panel-color);
      border-color: var(--main-border-color);
      padding: 4px;
      &.queue-img {
        width: map-get($waggel-queue-icon-size, width);
        height: map-get($waggel-queue-icon-size, height);
        top: map-get($waggel-queue-icon-size, top);
        left: map-get($waggel-queue-icon-size, left);
        border-radius: map-get($waggel-queue-icon-size, border-radius);
      }
    }
  }
}
