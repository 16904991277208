:root,
.light-theme {
  @include light-theme;
}
.dark-theme {
  @include dark-theme
}
.os-theme {
  @media (prefers-color-scheme: light) {
      @include light-theme;
  }

  @media (prefers-color-scheme: dark) {
      @include dark-theme;
  }
}
