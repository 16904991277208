app-edd-form {
  @mixin grid-min-max-cols($min-cols, $max-cols, $cols-min-width, $grid-row-gap: 0px, $grid-column-gap: 0px) {
    --min-cols: #{$min-cols};
    --max-cols: #{$max-cols};
    --cols-min-width: #{$cols-min-width};
    --grid-row-gap: #{$grid-row-gap};
    --grid-column-gap: #{$grid-column-gap};

    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(min((100%/var(--min-cols) - var(--grid-column-gap)*(var(--min-cols) - 1)/var(--min-cols)),
        max(var(--cols-min-width),
          (100%/var(--max-cols) - var(--grid-column-gap)*(var(--max-cols) - 1)/var(--max-cols)))), 1fr));
    gap: $grid-row-gap $grid-column-gap;
  }
  .group-header {
    app-edd-form-media {
      display: inline-block;
      position: relative;
      top: 7px;
      margin-right: 5px;
    }
  }
  .group-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    > * {
      margin-left: $sp-md;
    }
  }
  .group-header {
    position: relative;
  }
  .group-header-expand {
    position: absolute;
    right: 0;
    top: 0;
  }
  .group-contents {
    height: auto;
    overflow: hidden;

    &.collapsed {
      height: 0;
    }
  }
  .data-points-container {
    width: 100%;
    display: grid;
    @include grid-min-max-cols(1, 4, 300px, $sp-md, $sp-md);
  }
  .input-container {
    display: flex;
    align-items: flex-start;
    > div {
      width: 100%;
    }
    mat-form-field {
      width: 100%;
    }
  }
  .full-width {
    grid-column: 1/-1; // span all columns width
  }
  .error-section {
    border: 1px solid var(--error-background);
  }

  .section-description {
    margin-bottom: $sp-sm;
  }

  .group-name {
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    padding-bottom: $sp-xs;
    line-height: 24px;
  }

  .link-btn {
    background: #26C2EB;
    padding: 2px $sp-sm;
    border-radius: 8px;
    position: relative;
    margin-bottom: $sp-sm;
    mat-icon {
      --cadesi-icon-size: 18px;
      width: var(--cadesi-icon-size);
      height: var(--cadesi-icon-size);
      line-height: var(--cadesi-icon-size);
      font-size: var(--cadesi-icon-size);
    }
    span {
      margin-left: $sp-xs;
      color: white;
      font-size: 13px;
      font-weight: 600;
    }
  }
  app-dynamic-consultation-media {
    display: block;
    margin: $sp-md 0 $sp-sm 0;
  }
}
