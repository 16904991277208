.media-holder {
  section {
    width: 80px;
    position: relative;
    margin-top: $sp-md;
    margin-right: $sp-md;
    display: inline-block;
  }
}
.media-preview-holder {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid grey;
  cursor: pointer;
  overflow: hidden;
  width: 80px;
  max-height: 80px;
}

.media-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  flex-wrap: wrap;
  height: 100%;
  padding: 8px;
  mat-icon {
    margin: 0;
    margin-bottom: 4px;
  }
  span {
    font-size: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
  }
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: darkgray;
  }
}

.media-preview-holder .image-preview img {
  width: auto;
  height: 100%;
}

.video-preview, .doc-preview, .image-preview {
  width: 80px;
  height: 80px;
  text-align: center;
}

.video-preview .mat-icon, .doc-preview .mat-icon {
  font-size: 30px;
  width: 30px;
  color: darkgray;
}

.hover-preview {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  position: absolute;
  top: 0;
  left: -100%;
}

.hover-preview .mat-icon {
  font-size: 30px;
  width: 30px;
  margin-top: 30%;
  color: lightgrey;
}

.media-preview-holder:hover .hover-preview {
  left: 0;
}

.clear-button-container {
  position: absolute;
  right: -13px;
  top: -13px;
  background: var(--main-title-color);
  display: inline-flex;
  padding:4px;
  z-index: 1;
  border-radius: 50%;
  mat-icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
    rotate: 0deg;
    transition: all 100ms linear;
  }
  &:hover {
    background: var(--main-title-color-hover);
    cursor: pointer;
    mat-icon {
      rotate: 90deg;
    }
  }
}
