app-treatment-plan-problem-builder {
  @mixin venom-overlay {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    z-index: 1;
    background: var(--overlay-background);
  }
  .add-problem-panel {
    background: var(--main-body-background);
    padding: 10px;
    border-radius: $panel-border-radius;
    border: 1px solid;
    border-color: var(--main-border-color);
    min-height: 100px;
  }

  .chosen-problems-panel {
    margin: 10px 0;
    border-radius: 4px;

    .problem {
      border-radius: 8px;
      border: 1px solid;
      margin: 10px 0;
      border-color: var(--main-border-color);

      &.chosen-problem {
        outline: var(--success-background) 1px solid;
      }

      .problem-title {
        background: var(--table-header-background);
        @include flex($align-items: center, $justify-content: space-between);
        &.problem {
          @include problem-color-modifiers;
        }
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-block-start: 0;
        margin-block-end: 0;
        padding: 8px;
      }

      .problem-text {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .problem-number {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: red;
        display: inline-block;
      }

      .actions {
        @include flex;
      }

      mat-icon {
        color: var(--main-text-color);
        &:hover {
          color: var(--secondary-text-color);
        }
      }

      .problem-content {
        background: var(--main-body-background);
        p {
          margin-bottom: 0;
        }
        padding: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .items {
          @include flex($align-items: flex-start);
          span {
            color: var(--secondary-text-color);
          }
          margin-bottom: 4px;
          mat-icon {
            font-size: 18px;
            width: 18px;
            height: 18px;
            line-height: 21px;
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .picker-title {
    position: relative;

    .mat-icon-button {
      position: absolute;
      top: -14px;
      right: -4px;
    }
  }
  app-venom-code-picker .search-holder .form-control {
    padding: 0;
  }
  .loading-overlay {
    @include venom-overlay;
    @include flex($align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .error-overlay {
    @include venom-overlay;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
      color: var(--error-background);
    }
    button {
      margin: 0 8px;
    }
  }

  .action-panel {
    position: relative;
  }
}
