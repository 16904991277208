app-venom-code-picker {
  .venom-codes-holder {
    width: 100%;
    padding: 10px;
    background: var(--table-background);
    border: 1px solid;
    border-color: var(--main-border-color);
    position: absolute;
    top: 47px;
    z-index: 999;
    margin-bottom: 20px;
    max-height: 250px;
    overflow: auto;
  }

  .input-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 997;
  }

  .no-results p {
    margin-bottom: 0;
  }

  .inputs-holder {
    position: relative;
  }

  .expanded.category-label, .expanded .mat-icon {
    color: black !important;
  }

  .venom-codes-holder ul {
    padding-left: 10px;
    margin-bottom: 0;
  }

  .venom-codes-holder ul.root-list {
    padding-left: 0;
  }

  .search-holder {
    position: relative;
    margin-bottom: 10px;
    z-index: 1;
    box-sizing: border-box;
  }

  .search-holder.drop-down-expanded {
    z-index: 998;
  }

  .search-holder .form-control {
    width: 100%;
    text-overflow: ellipsis;
    padding-left: 8px;
    padding-right: 30px;
    background: none;
    border: none;
    border-bottom: 1px solid;
    border-color: var(--main-border-color);
    border-radius: 0;
    font-size: 14px;
  }

  .search-holder .form-control::placeholder {
    color: var(--main-text-color);
  }

  .clear-btn {
    position: absolute;
    top: 9px;
    right: 7px;
    cursor: pointer;
  }

  .nested-level {
    padding-left: 10px;
  }

  .venom-code-term {
    padding-left: 15px;
    font-style: italic;
    font-weight: normal;
    cursor: pointer;
    color: var(--main-text-color);
  }

  .category-item {
    font-size: 13px;
    font-weight: $bold-default;
    cursor: pointer;
    user-select: none;
  }

  .category-label {
    font-size: 13px;
    color: var(--main-text-color);
    position: relative;
    top: -6px;
  }

  .category-item .mat-icon {
    color: var(--main-text-color);
  }

}
