.mat-dialog-container {
  background: var(--panel-background);
  z-index: 1002;
  overflow-x: hidden !important;
}

.media-dialog {
  .mat-dialog-container {
    padding: 0;
  }
  .mat-dialog-content {
    max-height: 95vh;
  }
}

.pet-history-dialog {
  .mat-dialog-container {
    width: 100vw;
  }
}


.add-edit-appointment-dialog {
  .mat-dialog-container {
    width: 40vw;
    height: auto;
  }
  .mat-dialog-content {
    overflow: hidden;
    height: auto;
    max-height: unset;
  }
}

.appointment-picker-dialog {
  .mat-dialog-container {
    width: 80vw;
    height: auto;
  }
  .mat-dialog-content {
    max-height: unset;
  }
}

.consultation-completion-retry-dialog {
  .mat-dialog-container {
    width: 50vw;
    max-width: 650px;
  }
}

.hills-reco-dialog {
  .mat-dialog-container {
    width: 85vw;
    height: 85vh;
    overflow: hidden;
    position: relative;
  }
}

.basket-unpaid-dialog {
  .mat-dialog-container {
    width: 400px;
  }
}


