body {
  background: var(--main-body-background);
}

html {
  font-size: 14px;
}

hr {
  background-color: var(--secondary-text-color);
  opacity: 1;
}

.page-holder {
  margin-top: $header-height + $header-offset;
  margin-left: $menu-width + $menu-offset;
}

.full-width {
  width: 100%;
}

.margin-b-xs {
  margin-bottom: $sp-xs;
}

.margin-b-sm {
  margin-bottom: $sp-sm;
}

.margin-b-md {
  margin-bottom: $sp-md;
}

.margin-b-lg {
  margin-bottom: $sp-lg;
}

.margin-b-xl {
  margin-bottom: $sp-xl;
}

.margin-b-xs {
  margin-bottom: $sp-xs;
}

.margin-r-sm {
  margin-right: $sp-sm;
}

.margin-r-md {
  margin-right: $sp-md;
}

.margin-r-lg {
  margin-right: $sp-lg;
}

.margin-r-xl {
  margin-right: $sp-xl;
}

.p-t-15 {
  padding-top: 15px;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.m-tb-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.flex-row {
  @include flex;
}

.blur-app {
  filter: blur(4px);
  height: 100vh;
  overflow: hidden;
}

.no-x-scroll {
  overflow-x: hidden;
}

.no-y-scroll {
  overflow-y: hidden;
}

.no-scroll {
  overflow: hidden;
}

