app-history-pets {
  .mat-table {
    tr.example-detail-row {
      td.cdk-column-expandedDetail {
        border-bottom: 1px solid;
        background: var(--table-background);
        border-color: var(--main-border-color);
      }
    }
    .mat-column-breeds {
      max-width: 350px;
    }
  }

  .info-icon {
    color: var(--main-title-color);
    &:hover {
      color: var(--main-title-color-hover);
    }
  }
}
