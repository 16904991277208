app-outcomes {
  .input-grid {
    display: grid;
    column-gap: 8px;
    @include media-breakpoint-up(xxl) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include media-breakpoint-between(xl, xxl) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include media-breakpoint-between(md, xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media-breakpoint-between(sm, md) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
    * {
      word-break: break-word;
    }
  }

  .other-outcomes {
    border-bottom: 1px solid;
    margin-bottom: 8px;
    border-color: var(--main-border-color);
  }

  .checkbox-holder {
    margin-bottom: 10px;
  }
}
