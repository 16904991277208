.mat-autocomplete-panel {
  background: var(--panel-background);
  &.mat-active, .mat-active {
    background: var( --autocomplete-active-background);
  }
  .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: var(--panel-background);
  }
}

.p-autocomplete
{
  .p-autocomplete-input {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: var(--secondary-text-color);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: var(--secondary-text-color);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: var(--secondary-text-color);
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: var(--secondary-text-color);
    }
  }
}

