.mat-table {
  background: var(--table-background);

  .mat-cell, .mat-header-cell {
    color: var(--main-text-color);
  }

  .mat-header-cell, .mat-sort-header-content {
    font-size: $table-header-font-size;
    font-weight: $bold-default;
  }

  td {
    text-transform: capitalize;
  }

  .mat-column-email {
    text-transform: none;
  }
}

tr.expand-row {
  cursor: pointer;
  &:hover {
    background: var(--table-row-hover-background);
  }
}


.mat-paginator {
  background: var(--table-background);
  color: var(--main-text-color);
  .mat-paginator-page-size {
    align-items: center !important;
  }
}


