.queue-header-container {
  @include flex($justify-content: space-between);
}
.queue-header-actions {
  @include flex;
  mat-icon {
    cursor: pointer;
    display: block;
    opacity: 0.5;
    &.selected {
      opacity: 1;
    }
  }
  mat-slide-toggle {
    margin-right: $sp-sm;
  }
}
