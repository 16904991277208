.profile-text-danger, .profile-text-danger * {
  color: var(--error-background);
}

.profile-text-warning, .profile-text-warning * {
  color: var(--warning-background);
}

.profile-text-success, .profile-text-success * {
  color: var(--success-background);
}
