$calendar-popover-max-height: 300px;


.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: var(--table-background);
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: var(--main-border-color);
}

.fc.fc-theme-standard .fc-view-harness td, .fc.fc-theme-standard .fc-view-harness th {
  background: var(--table-background);
  border-color: var(--main-border-color);

  a {
    color: var(--main-text-color);
  }
}


.fc-event-title, .fc-event-time, .fc-daygrid-day-number, .fc-daygrid-week-number, .fc-daygrid-month-number,
.fc-col-header-cell-cushion  {
  color: var(--main-title-color);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// Block events
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event, .fc-event.fc-timegrid-event {
  cursor: pointer;
  color: white;
  &.appointment-status {
    position: relative;
    &:hover {
      &::after {
        content: attr(data-appointment-content);
        position: absolute;
        bottom: calc(100% + 8px);
        transform-origin: bottom;
        width: 100%;
        overflow-wrap: break-word;
        white-space: normal;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        background: var(--video-call-background-color);
        padding: 4px;
        z-index: 7;
        color: var(--main-text-color);
      }
    }
  }
  .fc-event-title, .fc-event-time {
    color: white;
  }
  &.appointment-status {
    &-confirmed, &-initiated, &-joined-triage {
      background: $appointment-confirmed-color;
      border-color: $appointment-confirmed-color;
    }
    &-completed {
      background: $appointment-completed-color;
      border-color: $appointment-completed-color;
    }
    &-pending, &-unconfirmed {
      background: $appointment-pending-color;
      border-color: $appointment-pending-color;
    }
    &-denied, &-missed, &-cancelled {
      background: $appointment-denied-color;
      border-color: $appointment-denied-color;
    }
    &-new-booking {
      background: var(--main-title-color);
      border-color: var(--main-title-color);
    }
  }
  &.slot-block {
    background: var(--rota-block-background-color);
  }
  &.slot-appointment {
    background: rgba(var(--rota-appointment-background-color), 1)!important;
  }
  &.appointment-duration {
    @mixin duration-height($slot-duration: 15, $appointment-duration: 0, $padding: 10px) {
      height: calc(1.5em * ($appointment-duration / $slot-duration) + $padding)
    }
    &.duration-60 {
      @include duration-height($appointment-duration: 60, $padding: 15px);
    }
    &.duration-45 {
      @include duration-height($appointment-duration: 45);
    }
    &.duration-30 {
      @include duration-height($appointment-duration: 30);
    }
    &.duration-15 {
      @include duration-height($appointment-duration: 15);
    }
  }
}

// Dot events
.fc.fc-theme-standard .fc-view-harness .fc-daygrid-event.fc-daygrid-dot-event {
  cursor: pointer;
  &.appointment-status {
    position: relative;
    &:hover {
      &::after {
        content: attr(data-appointment-content);
        position: absolute;
        bottom: calc(100% + 8px);
        transform-origin: bottom;
        width: 100%;
        overflow-wrap: break-word;
        white-space: normal;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        background: var(--video-call-background-color);
        padding: 4px;
        z-index: 7;
        color: var(--main-text-color);
      }
    }
  }
  .fc-event-title, .fc-event-time {
    color: var(--main-text-color);
  }
  &:hover {
    background: transparent;
    .fc-event-title, .fc-event-time {
      color: var(--secondary-text-color);
    }
  }

  &.appointment-status {
    &-completed {
      .fc-daygrid-event-dot {
        background: $appointment-completed-color;
        border-color: $appointment-completed-color;
      }
    }
    &-confirmed, &-joined-triage, &-initiated {
      .fc-daygrid-event-dot {
        background: $appointment-confirmed-color;
        border-color: $appointment-confirmed-color;
      }
    }
    &-pending, &-unconfirmed {
      .fc-daygrid-event-dot {
        background: $appointment-pending-color;
        border-color: $appointment-pending-color;
      }
    }
    &-denied, &-missed, &-cancelled {
      .fc-daygrid-event-dot {
        background: $appointment-denied-color;
        border-color: $appointment-denied-color;
      }
    }
    &-new-booking {
      .fc-daygrid-event-dot {
        background: var(--main-title-color);
        border-color: var(--main-title-color);
      }
    }
  }
  &.slot-block {
    .fc-daygrid-event-dot {
      background: rgba(var(--rota-block-background-color), 1)!important;
    }
  }
  &.slot-appointment {
    .fc-daygrid-event-dot {
      background: rgba(var(--rota-appointment-background-color), 1) !important;
    }
  }
}

.fc.fc-theme-standard .fc-view-harness td.fc-daygrid-day.fc-day.fc-day-today {
  .fc-daygrid-day-top a {
    display: inline-block;
    color: white;
    font-size: 14px;
    line-height: 14px;
    background: var(--main-title-color);
    font-weight: $bold-default;
    padding: 4px;
    margin-top: 2px;
    margin-right: 2px;
    border-radius: 50%;
    min-width: 22px;
    text-align: center;
  }
}

.fc.fc-theme-standard .fc-view-harness td.fc-daygrid-day.fc-day.fc-day-disabled {
  background: var(--table-background-disabled);
  cursor: not-allowed;
  pointer-events: none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  background: var(--panel-background);
  border-color: var(--main-border-color);
  color: var(--main-text-color);
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  background: var(--panel-background);
  border-color: var(--main-border-color);
  color: var(--main-text-color);
  max-height: $calendar-popover-max-height;
  overflow: auto;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
  z-index: 100;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.fc-timegrid-more-link {
  background: var(--secondary-title-color) !important;
  color: white !important;
}

.fc-timegrid-more-link-inner {
  margin-top: -2px;
  color: white;
  border-color: white;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  color: var(--secondary-text-color) !important;
}

.fc .fc-bg-event {
  opacity: 1 !important;
  &.slot-block {
    background: rgba(var(--rota-block-background-color), 0.8)!important;
  }
  &.slot-appointment {
    background: rgba(var(--rota-appointment-background-color), 0.8)!important;
    width: calc(100% - 2.5%);
  }
  &.slot-rota {
    background: rgba(var(--rota-rota-background-color), 0.2)!important;
  }
  &.slot-unavailable {
    color: white;
    background: var(--table-background-disabled);
    .fc-event-title {
      color: white;
      font-style: normal !important;
    }
  }
  .fc-event-title {
    color: var(--main-text-color) !important;
    font-style: normal !important;
  }
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
  position: absolute;
  left: 50% !important;
}


