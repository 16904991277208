.mat-select-arrow {
  color: var(--main-text-color);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--main-text-color);
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--main-text-color);
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

.mat-form-field-appearance-standard .mat-form-field-infix {
  padding: 0 !important;
  border-top: none;
}

.mat-form-field-appearance-legacy .mat-hint {
  color: var(--main-text-color);
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: var(--main-text-color);
}

.mat-form-field-appearance-standard .mat-form-field-ripple, .mat-form-field-appearance-legacy.mat-focused .mat-form-field-ripple  {
  background-color: var(--main-title-color);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--secondary-text-color);
}

.mat-form-field-appearance-legacy .mat-form-field-ripple, .mat-form-field-appearance-legacy.mat-focused .mat-form-field-ripple  {
  background-color: var(--main-title-color);
}

.mat-select {
  height: $general-input-height;
  background: var(--input-background);
  color: var(--main-text-color);
}

.mat-select-panel {
  background: var(--table-background);
}

.mat-select-trigger {
  position: relative;
  top: 8px;
  padding-left: 10px;
}

.mat-form-field-infix {
  padding-bottom: 0 !important;
}

.mat-input-element {
  height: $general-input-height;
  background: var(--input-background);
  color: var(--main-text-color);
  border: none;
  padding-left: 10px;
  caret-color: var(--main-text-color) !important;
}

.mat-input-element::placeholder {
  color: var(--main-text-color);
}

.mat-select-placeholder {
  font-weight: normal;
}

.mat-checkbox-frame {
  border-color: var(--main-border-color);
}

.mat-radio-outer-circle {
  border-color: var(--main-border-color);
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: var(--disabled-accent);
  cursor: not-allowed;
}

.mat-form-field-subscript-wrapper {
  overflow: visible;
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: var(--disabled-accent);
}

.mat-slide-toggle {
  &.mat-primary {
    .mat-slide-toggle-thumb {
      background-color: var(--input-background);
    }

    &.mat-checked {
      .mat-slide-toggle-thumb {
        background-color: var(--main-title-color);
      }

      .mat-slide-toggle-bar {
        background-color: var(--input-toggle-dark-background);
      }
    }
  }
}

