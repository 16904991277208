app-treatment-plan-holder {
  .send-btn {
    flex-basis: 100%;
    float: right;
    margin: 10px;
  }
  .warning-text {
    color: var(--warning-background);
    margin: $sp-sm 10px;
    text-align: right;
    float: right;
    display: block;
    width: 100%;
  }
}
