app-treatment-plan-resources {
  @mixin resources-overlay {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    z-index: 1;
    background: var(--overlay-background);
  }
  .add-problem-panel {
    background: var(--main-body-background);
    padding: 10px;
    border-radius: $panel-border-radius;
    border: 1px solid;
    border-color: var(--main-border-color);
    min-height: 100px;
    .picker-title {
      @include flex($justify-content: space-between);
      .mat-icon-button {
        position: relative;
        top: -5px;
      }
    }
    .products-search.form-control {
      width: 100%;
      text-overflow: ellipsis;
      padding-left: 8px;
      margin-bottom: 24px;
      background: none;
      border: none;
      border-bottom: 1px solid;
      border-color: var(--main-border-color);
      border-radius: 0;
      font-size: 14px;
      &::placeholder {
        color: var(--main-text-color);
      }
    }

    .overlay-holder {
      width: 100%;
      padding: 10px;
      background: var(--table-background);
      border: 1px solid;
      border-color: var(--main-border-color);
      position: absolute;
      top: 42px;
      z-index: 999;
      margin-bottom: 20px;
      max-height: 250px;
      overflow: auto;
    }

    .resource-input-container {
      position: relative;
    }

    .overlay-holder ul {
      padding-left: 10px;
      margin-bottom: 0;
    }

    .overlay ul.root-list {
      padding-left: 0;
    }

    .expanded.category-label, .expanded .mat-icon {
      color: black !important;
    }

    .category-item {
      font-size: 13px;
      font-weight: $bold-default;
      cursor: pointer;
      user-select: none;
    }

    .category-label {
      font-size: 13px;
      color: var(--main-text-color);
      position: relative;
      top: -6px;
    }

    .resource-name {
      padding-left: 15px;
      font-style: italic;
      font-weight: normal;
      cursor: pointer;
      color: var(--main-text-color);
    }
    .selected-resources {
      margin-top: 10px;

      .resource-item {
        @include flex($align-items: center, $justify-content: space-between, $flex-wrap: wrap);
        font-size: 14px;
        line-height: 24px;
        padding: 4px;
        border-radius: 4px;
        border: 1px solid;
        border-color: var(--main-border-color);
        background: var(--video-call-background-color);
        margin-bottom: 8px;
      }
      .resource-warning {
        display: block;
        flex-basis: 100%;
        color: var(--warning-background);
      }
      ul {
        padding-inline-start: 0;
        list-style-type: none;
      }
      div {
        @include flex($align-items: center);
        cursor: pointer;
      }
      //span {
      //  justify-self: flex-start;
      //}
      mat-icon.document {
        margin-right: 8px;
        color: var(--error-background);
      }
      mat-icon.close {
        justify-self: flex-end;
        &:hover {
          cursor: pointer;
          color: var(--header-border-color);
        }
      }
    }
  }
  .input-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 997;
  }

  .loading-overlay {
    @include resources-overlay;
    @include flex($align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .error-overlay {
    @include resources-overlay;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
      color: var(--error-background);
    }
    button {
      margin: 0 8px;
    }
  }

  .action-panel {
    position: relative;
  }
}
