app-treatment-plan-summary {
  .add-problem-panel {
    background: var(--main-body-background);
    padding: 10px;
    border-radius: $panel-border-radius;
    border: 1px solid;
    border-color: var(--main-border-color);

    .picker-title {
      @include flex($justify-content: space-between);

      .mat-icon-button {
        position: relative;
        top: -5px;
      }
    }

    .product-inputs {
      mat-form-field {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    .products-search.form-control {
      width: 100%;
      min-height: 75px;
      text-overflow: ellipsis;
      padding-left: 8px;
      margin-bottom: 8px;
      background: none;
      border: none;
      border-radius: 0;
      font-size: 14px;
      &::placeholder {
        color: var(--main-text-color);
      }
    }
    mat-label {
      padding-left: 8px;
    }
  }
  .invalid {
    border-color: var(--error-background);
  }

  .submit-container {
    @include flex($flex-direction: column, $justify-content: flex-end, $align-items: flex-end);
  }
}
