@mixin flex($inline: false, $flex-direction: row, $align-items: flex-start, $justify-content: flex-start, $flex-wrap: nowrap, $flex-basis: auto, $flex-grow: false, $flex-shrink: false) {
  @if $inline {
    display: inline-flex;
  }
  @else {
    display: flex;
  }
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
  flex-basis: $flex-basis;

  @if $flex-grow != false {
    flex-grow: $flex-grow;
  }
  @if $flex-shrink != false {
    flex-shrink: $flex-shrink;
  }
}

@mixin remove-block-padding($start: 0, $end: 0) {
  margin-block-start: $start;
  margin-block-end: $end;
}

$problem-colors: (
  arctic-lime: #CAF3ED,
  onahauv: #B9FDFF,
  fog: #E9CBFF,
  dairy-cream: #f9dcba,
  mandys-pink: #F7CBBC
);

@mixin problem-color-modifiers {
  @each $name, $hex in $problem-colors {
    &-#{$name} {
      background: rgba($hex, .5);
    }
  }
}
