@mixin pet-profile-overlay {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-radius: 10px;
  z-index: 1;
  background: var(--overlay-background);
}

app-consultation-history {
  .consultation-history-container {
    position: relative;
  }
  .main-holder{
    position: relative;
    border: 1px solid;
    border-color: var(--main-border-color);
    background: var(--panel-background);
    border-radius: 4px;
    padding: 10px;
    min-height: 100px;
  }
  .empty-container {
    @include pet-profile-overlay;
    height: 100%;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
  }
  .loading-overlay {
    @include pet-profile-overlay;
    @include flex($align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .error-overlay {
    @include pet-profile-overlay;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
      color: var(--error-background);
    }
    button {
      margin: 0 8px;
    }
  }

  .action-panel {
    position: relative;
  }
}
