app-pet-history-download {
   .download-heading {
     margin-top: $sp-xs;
     margin-bottom: $sp-xs;
   }
  .download-warning {
    color: var(--warning-background);
  }
  .mat-flat-button, .mat-stroked-button {
    margin-right: $sp-sm;
  }
}
