app-history-users {
  .pets-card {
    background: var(--panel-background);
    border-radius: 8px;
    padding: 24px 10px;
  }
  .pets {
    @include flex($flex-direction: row, $justify-content: center, $flex-wrap: wrap);
  }
  .pet-thumbnail {
    @include flex($flex-direction: column, $justify-content: center, $align-items: center);
    padding: 4px 10px;
    position: relative;
    span {
      padding: 4px;
      font-weight: $default-weight;
      max-width: 150px;
    }

    .detail-loading-holder {
      position: absolute;
      top: 6px;
    }
  }
  .order-icon, .info-icon {
    color: var(--main-title-color);
    &:hover {
      color: var(--main-title-color-hover);
    }
  }
}
