app-login {
  app-messages {
    display: block;
    margin: 16px;
  }
  .login-holder {
    width: 100%;
    height: 100%;
    background: var(--login-background);
    position: absolute;

    header {
      text-align: center;

      .main-logo {
        display: inline-block;
        max-width: 200px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: auto;
        }
      }

      h1 {
        margin-bottom: 20px;
      }
    }

    .login-form {
      max-width: $login-form-max-width;
      margin: 50px auto;

      .mat-form-field-wrapper {
        padding-bottom: 10px;
      }

      .mat-form-field {
        width: 100%;
        display: inline-block;
      }

      .mat-flat-button {
        width: 100%;
        color: var(--primary-button-text);
      }
    }
    .actions-container {
      margin-top: $sp-sm;
      @include flex($justify-content: space-between);
      a {
        color: var(--main-text-color);
        text-decoration: underline;
        &:hover {
          text-decoration: underline;
          color: var(--main-title-color);
          cursor: pointer;
        }
        &:active {
          text-decoration: underline;
          color: var(--main-title-color-hover);
        }
      }
    }
  }
}
