app-calendar-holder {
  --header-height: #{$header-height};
  --sidebar-width: #{$menu-width};
  position: relative;
  width: 100vw;
  height: 100vh;
  display: block;
  .calendar-flex {
    display: flex;
    flex-wrap: nowrap;
    .calendar-holder {
      transition: flex-basis 100ms ease-in-out;
      flex-basis: 100%;
      &.open {
        flex-basis: calc(100% - 300px);
      }
    }
  }
  .loading {
    filter: blur(2px);
  }
  app-calendar {
    width: 100%;
  }
  full-calendar {
    margin-right: $panel-padding;
    margin-bottom: $panel-padding;
  }
  .mat-select {
    width: 150px;
    border-radius: 2px;
    border: 1px solid;
    border-color: var(--main-border-color);
    background-color: var(--main-panel-color);
  }
  .mat-select-placeholder {
    color: var(--main-text-color);
  }

  .select-container {
    @include flex($align-items: center);
    margin-top: 2px;
    mat-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
      margin-left: 4px;
      margin-top: -$sp-md;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .calendar-loading-holder {
    background: rgba(black, 0.3);
    position: fixed;
    border-radius: 3px;
    padding: $sp-md $sp-xl;
    width: calc(100vw - var(--sidebar-width));
    height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: var(--header-height);
    left: var(--sidebar-width);
    pointer-events: none;
    z-index: 100;
  }

  .filters-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: $sp-sm;
  }
  //.staff-filter, .staff-filter .p-autocomplete, .staff-filter .p-autocomplete-input {
  //  width: calc(100% - 24px);
  //  //width: 250px;
  //  //margin-left: $sp-xs;
  //}
  full-calendar {
    height: calc(100vh - $header-height - $header-offset - 46px);
  }
  button {
    background: var(--main-panel-color);
    color: var(--main-title-color);
    border-color: var(--header-border-color);
    border: 1px solid;
    border-radius: $button-border-radius;
    margin-right: $sp-sm;

    span {
      color: var(--main-title-color);
      font-weight: $bold-default;
    }
    &:focus {
      outline: none;
    }
    &.left-button, &.right-button {
      background: var(--main-title-color);

      span {
        color: var(--primary-button-text);
      }
    }
  }

  .filters-bar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $sp-sm;
    align-items: center;

    mat-icon {
      color: var(--main-title-color);
      margin-right: $sp-sm;
    }

    mat-hint {
      color: var(--secondary-text-color);
    }

    .p-element {
      .p-tag {
        background: var(--main-title-color);
        margin-right: $sp-sm;
        cursor: pointer;
      }

      &.confirmed, &.initiated, &.joined-triage {
        .p-tag {
          background: $appointment-confirmed-color;
        }
      }

      &.completed {
        .p-tag {
          background: $appointment-completed-color;
        }
      }

      &.pending, &.unconfirmed {
        .p-tag {
          background: $appointment-pending-color;
        }
      }

      &.denied, &.missed, &.cancelled {
        .p-tag {
          background: $appointment-denied-color;
        }
      }
      .p-tag-icon {
        color: white;
        margin-left: 0.25rem;
      }
    }
  }
}


.staff-info-icon {
  color: var(--main-title-color);
}

.calendar-filters-sidebar {
  .filters-heading {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    mat-icon {
      color: var(--main-title-color);
      margin-right: $sp-sm;
    }
    h4 {
      margin: 0;
      font-size: 20px
    }
  }

  .filters-container {
    margin-top: $sp-md;

    mat-label {
      display: block;
      flex-basis: 100%;
      color: var(--secondary-text-color);
      font-size: 13px;
      font-weight: bold;
    }
  }
  .filter-input {
    margin-bottom: $sp-md;
    display: flex;
    flex-wrap: wrap;
    mat-icon {
      &.filter, &.reset {
        align-self: center;
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
      &.reset {
        margin-left: $sp-xs;
        flex-shrink: 0;
      }
    }
    mat-error {
      margin-top: $sp-xs;
    }
    p-autocomplete {
      width: calc(100% - 28px);
    }
    app-appointment-type-picker-dropdown {
      display: block;
      width: calc(100% - 28px);
    }
    .p-autocomplete, .p-autocomplete-input {
      width: 100%;
    }
  }
  .clear-btn {
    display: block;
  }
  .p-sidebar-content {
    height: 100%;
  }
  .calendar-toolbar {
  }
}
