.mat-accordion {
  .mat-expansion-panel {
    background: var(--main-panel-color);
    border-radius: 0;
    border: 1px solid var(--main-border-color);
    box-shadow: none;
  }
  .mat-expansion-indicator::after {
    color: var(--main-text-color);
  }
  .mat-expansion-panel-header {
    background: var(--main-panel-color);
    border-radius: 0;
    &:focus {
      background: var(--main-panel-color);
    }
    &:hover {
      background: var(--main-panel-color-hover);
    }
    &:hover {
      :not([aria-disabled=true]) {
        background: var(--main-panel-color-hover);
      }
    }
  }
  .mat-expansion-panel-header-description {
    color: var(--secondary-text-color);
  }
  .mat-expansion-panel-header.mat-expanded {
    border-radius: 0;
    background: var(--main-panel-color);
    &:focus {
      background: var(--main-panel-color);
    }
    &:hover {
      background: var(--main-panel-color-hover);
    }
    &:hover {
      :not([aria-disabled=true]) {
        background: var(--main-panel-color-hover);
      }
    }
  }
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header {
    &:hover {
      background: var(--main-panel-color-hover);
    }
    &:hover {
      :not([aria-disabled=true]) {
        background: var(--main-panel-color-hover);
      }
    }
  }
  .mat-expansion-panel-header-title {
    color: var(--main-text-color);
  }
}
