app-table-search-bar {
  .search-bar {
    width: 100%;
    position: relative;

    .options-holder {
      width: $search-input-width;
      position: relative;
    }

    .clear-btn {
      position: absolute;
      top: 7px;
      right: 10px;
      cursor: pointer;
    }

    .mat-input-element {
      height: 21px;
      color: var(--main-text-color);
      -webkit-text-fill-color: var(--main-text-color);
      // This is kinda ugly but Chrome Autofill is a pain to override (will build a mixin for this perhaps)
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $main-white inset !important;
        @media (prefers-color-scheme: dark) {
          -webkit-box-shadow: 0 0 0 30px $dark-background-tint inset !important;
        }
      }
    }

    .search-options-pre, .search-options-post {
      position: absolute;
      top: 5px;
      z-index: 1;

      .mat-form-field {
        width: $search-select-width;
      }

      .mat-form-field-infix {
        height: $search-select-height;

        .mat-select {
          height: 20px;
          background: transparent;
          float: right;
          position: relative;
          top: -4px;

          .mat-select-trigger {
            padding-left: 0;
            top: 0;
          }
        }
      }
    }

    .search-options-pre {
      left: 5px;

      .mat-select-value-text {
        position: relative;
        top: -2px;
      }
    }

    .search-options-post {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 16px;

      mat-date-range-input {
        margin-right: 4px;
      }

      .mat-date-range-input-inner {
        text-align: left;
      }

      .mat-form-field {
        width: $search-date-picker-width;
        margin-right: 0;
        top: -5px;
      }

      .mat-date-range-input {
        position: relative;
        top: -2px;
      }

      .mat-form-field-infix {
        border: none;
        text-align: right;
      }

      .mat-form-field-label-wrapper {
        display: none;
      }

      .mat-button-wrapper svg {
        width: 24px;
        position: relative;
        top: -3px;
      }

      .mat-date-range-input-separator {
        color: var(--main-text-color);
      }

      &.clear-btn {
        right: 30px;
        .mat-form-field {
          margin-right: 16px;
        }
      }
    }

    .mat-form-field {
      width: $search-input-width;
      margin-right: 10px;
      padding-top: 10px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 10px;
    }

    .mat-form-field-infix {
      height: $search-height;
      font-size: $search-font-size;
      background: var(--search-background);
      line-height: 20px;
      border: 1px solid;
      border-color: var(--search-border-color);
      border-radius: 6px;
      padding-left: 10px;
      box-sizing: border-box;
    }

    .has-pre-options .mat-form-field-infix {
      padding-left: $search-select-width + 10px;
    }

    .has-pre-options .mat-form-field-label span {
      padding-left: $search-select-width + 10px;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-label span {
      padding-left: 10px;
      font-size: $search-font-size !important;
      line-height: 25px !important;
    }
  }
}
