.media-inputs-holder, .media-type-input, .media-btn-holder, .media-form-holder {
  ////display: inline-block;
  //display: flex;
}

.media-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -$sp-xs;
}

.media-form-holder {
  margin-right: 10px;
}

.media-btn-holder {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  button {
    margin-top: $sp-md;
    margin-right: 10px;
  }
}

.supported-types {
  font-size: 12px;
}
