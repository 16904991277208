app-appointment-type-picker {
  .appointment-picker-holder {
    display: block;
    width: 100%;
    height: 200px;
    overflow: auto;
    padding: 10px;
    border: 1px solid;
    border-radius: 6px;
    text-align: left;
    border-color: var(--main-border-color);
  }

  .category-item {
    cursor: pointer;
  }

  .appointment-picker-holder .list-unstyled {
    margin-bottom: 0;
    padding-left: 10px;
  }

  .category-label {
    font-size: 13px;
    font-weight: $bold-default;
    color: var(--main-title-color);
    position: relative;
    top: -6px;
    text-transform: capitalize;
  }

  .root-list {
    padding-left: 0 !important;
  }

  .appointment-type {
    font-size: 13px;
    padding-left: 15px;
    font-style: italic;
    font-weight: normal;
    cursor: pointer;
  }

  .search-holder {
    position: relative;
    margin-bottom: 10px;
  }

  .clear-btn {
    position: absolute;
    top: 9px;
    right: 7px;
    cursor: pointer;
  }

}
