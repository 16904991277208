app-rota-calendar {
  --header-height: #{$header-height};
  --sidebar-width: #{$menu-width};

  .loading {
    filter: blur(2px);
  }

  .calendar-loading-holder {
    background: rgba(black, 0.3);
    position: fixed;
    border-radius: 3px;
    padding: $sp-md $sp-xl;
    width: calc(100vw - var(--sidebar-width));
    height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: var(--header-height);
    left: var(--sidebar-width);
    pointer-events: none;
    z-index: 100;
  }

  .filters-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: $sp-sm;
  }

  .filters-bar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $sp-sm;
    align-items: center;

    mat-icon {
      color: var(--main-title-color);
      margin-right: $sp-sm;
    }

    mat-hint {
      color: var(--secondary-text-color);
    }

    .p-element {
      .p-tag {
        background: var(--main-title-color);
        margin-right: $sp-sm;
        cursor: pointer;
      }

      &.confirmed, &.initiated, &.joined-triage {
        .p-tag {
          background: $appointment-confirmed-color;
        }
      }

      &.completed {
        .p-tag {
          background: $appointment-completed-color;
        }
      }

      &.pending, &.unconfirmed {
        .p-tag {
          background: $appointment-pending-color;
        }
      }

      &.denied, &.missed, &.cancelled {
        .p-tag {
          background: $appointment-denied-color;
        }
      }
      .p-tag-icon {
        color: white;
        margin-left: 0.25rem;
      }
    }
  }

  .preview-heading {
    font-size: 1rem;
    font-weight: 600;
    color: var(--secondary-text-color);
    display: flex;
    align-items: center;
  }

  full-calendar {
    // Manage calendar height to remove page scrollbar
    --rota-header-height: 184px;
    height: calc(100vh - var(--rota-header-height));
    @media (max-width: 950px) {
      --rota-header-height: 220px;
    }
    @media (max-width: 550px) {
      --rota-header-height: 250px;
    }
  }
}
