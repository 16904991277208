app-video-consultation {
  .video-consult-card-container {
    background: var(--panel-background);
    padding: 10px;
    margin-bottom: 0;

    .video-consult {
      min-height: 75vh;
      border: 1px solid;
      position: relative;
      background: var(--video-call-background-color);
      border-color: var(--main-border-color);

      #staff-video-content {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      #client-video-content {
        position: absolute;
        background: var(--main-body-background);
        width: 100%;
        height: 100%;
      }
    }

    .overlay-active {
      filter: blur(4px);
    }

    .calling-overlay {
      width: 100%;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);

      p {
        margin: 0 0 0 10px;
      }
    }

    .video-consult-container {
      position: relative;
    }

    .call-actions {
      @include flex($justify-content: space-between, $align-items: center);
      padding: 10px 4px;
      border: 1px solid;
      border-top: none;
      background: var(--main-body-background);
      border-color: var(--main-border-color);

      .call-start-actions {
        @include flex($justify-content: center);
      }

      .actions-container {
        @include flex($justify-content: center);
      }

      .call-client {
        mat-icon {
          color: $success;
        }
      }

      .post-review {
        margin: 0;
      }

      .call-duration {
        margin-bottom: unset;
        font-weight: $bold-default;
        padding-right: 16px;
        cursor: default;
      }
    }

    .call-details-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(black, 0.4);
      top: 0;

      .call-options {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include flex($flex-wrap: wrap, $justify-content: center, $align-items: center);

        .call-title {
          flex-basis: 100%;
          text-align: center;
          color: var(--main-text-color);
          margin-bottom: 24px;
        }

        button {
          margin: 0 10px;
        }

        .no-show {
          background: $orange-mid;
        }

        .call-btn {
          background: $success;
        }

        .no-margin {
          margin: 0;
        }
      }
    }
  }
  .staff-audio-level {
    top: 16px;
    right: 16px;
    position: absolute;
  }

  .client-audio-level {
    top: 16px;
    left: 16px;
    position: absolute;
  }

}
