app-pet-practice-assignment {
  form, .mat-form-field, mat-radio-group {
    display: block;
  }
  .results, .register-btn, .assign-btn, .not-found, mat-radio-group, .expandable {
    margin-top: $sp-md;
  }

  .assign-btn {
    margin-left: 10px;
  }

  .warning-message {
    .mat-icon {
      position: relative;
      top: 5px;
    }
  }
}
