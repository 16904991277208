$signature-width: 450px;
$signature-height: 210px;

app-edd-form-signature {
  .signature-canvas {
    display: block;
    background: var(--edd-signature-background);
    margin-bottom: 10px;
    border: 1px solid var(--main-border-color);
  }

  .buttons-group {
    margin-bottom: 20px;

    button {
      margin-right: 10px;
    }
  }
}
