app-queue-widget {
  .queue-details {
    margin-right: 10px;
    padding: 4px 8px;
    border-radius: 4px;
    height: 100%;
    cursor: pointer;
    @include flex($align-items: center);

    &:hover {
      background: rgba(#000, 0.04);
    }

    .queue-title {
      @include flex($align-items: center);
      margin-right: $sp-xs;
      p {
        margin: 0;
      }
      mat-icon {
        margin-right: 4px;
      }
    }
    .queue-content {
      span {
        font-weight: $bold-default;
        color: var(--main-text-color);
        margin-right: 8px;
        white-space: nowrap;
      }
    }
  }
}

.queue-container {
  @include flex;
}

.queue-overlay-container {
  background: var(--panel-background);
  border: 1px solid var(--main-border-color);
  border-radius: 4px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  margin-top: 4px;
  mat-dialog-container {
    padding: $sp-sm;
  }
  h6 {
    padding-top: 8px;
    padding-left: 8px;
  }

  .scroll-list {
    max-height: 256px;
    overflow: auto;
  }

  .empty-container {
    height: 64px;
    width: calc(100% - 8px);
    min-width: 300px;
    position: relative;
    border: 1px solid;
    margin: 8px 4px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.1);
    border-color: var(--main-border-color);
    img {
      width: 25px;
      height: 25px;
      filter: grayscale(100%);
      margin-left: 4px;
    }
    p {
      margin-bottom: 0;
      padding-left: 8px;
      white-space: nowrap;
      padding-right: 8px;
    }
  }
}
