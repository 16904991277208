@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

h1, h2, h3, h4, h5, h6 {
  color: var(--main-title-color);
  font-family: 'Montserrat', sans-serif;
  font-weight: $bold-heading;
  letter-spacing: 0.01rem;
  font-kerning: none;
}

h1, h1 span, h1 .mat-icon {
  font-size: $header-one-size;
  font-weight: $bold-default;
}

h2 {
  font-size: $header-two-size;
}

div, p, span, a {
  font-family: 'Roboto', sans-serif;
  font-size: $paragraph-size;
  font-weight: $default-weight;
  color: var(--main-text-color);
}

strong {
  color: var(--main-text-color);
  font-weight: $bold-default;
}

a:hover {
  color: var(--main-title-color);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.joii-text-success {
  color: var(--success-text-color);
}

.joii-text-info {
  color: $bright-blue;
}

.joii-text-warning {
  color: var(--warning-text-color);
}

.joii-text-danger,
.joii-text-danger span,
.joii-text-danger .mat-icon {
  color: var(--danger-text-color);
}
