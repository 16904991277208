app-treatment-plan-products {
  @mixin product-overlay {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    z-index: 1;
    background: var(--overlay-background);
  }

  .product-header {
    margin-bottom: 10px;
  }

  .practice-autocomplete {
    width: 100%;

    input.mat-input-element {
      padding-left: 10px;
    }

    .mat-form-field-label {
      position: relative;
      top: 16px;
      left: 10px;
    }

    .mat-form-field {
      width: 100%;
    }
  }
  .label-details {
    word-wrap: break-word;
    white-space: break-spaces;
  }

  .label-details-holder {
    position: relative;
  }

  .label-actions {
    position: absolute;
    top: -7px;
    right: 0;

    .mat-icon {
      position: relative;
      top: -1px;
    }
  }

  .selected-product-title {
    position: relative;
    .toggle-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .add-problem-panel {
    background: var(--main-body-background);
    padding: 10px;
    border-radius: $panel-border-radius;
    border: 1px solid;
    min-height: 100px;
    border-color: var(--main-border-color);

    &.pom-product {
      border-color: var(--pom-product-highlight);
    }

    &.error-border {
      border-color: var(--error-background);
    }

    .product-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      button {
        flex-shrink: 0;
        margin-bottom: $sp-sm;
      }
    }
    .picker-title {
      @include flex($justify-content: space-between);
      .mat-icon-button {
        position: relative;
        top: -5px;
      }
    }
    .products-search.form-control {
      width: 100%;
      text-overflow: ellipsis;
      padding-left: 8px;
      margin-bottom: 24px;
      background: none;
      border: none;
      border-bottom: 1px solid;
      border-color: var(--main-border-color);
      border-radius: 0;
      font-size: 14px;
      &::placeholder {
        color: var(--main-text-color);
      }
    }
  }
  .invalid {
    border-color: var(--error-background);
  }
  .selected-product {
    position: relative;
    .flex-container {
      @include flex;
    }
    .product-title {
      @include flex($justify-content: space-between);
    }
    mat-form-field {
      width: 100%;
    }
    .product-desc {
      width: 100%;
      display: inline;

      p {
        font-size: 13px;
        line-height: 16px;
        padding-left: 10px;
        margin-bottom: 0;
        max-width: 65%;
        &:first-of-type {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 8px;
        }
        &.description-toggle {
          margin-left: $sp-md;
          margin-top: $sp-xs;
          color: var(--main-title-color);
          font-weight: bold;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .product-inputs {
      padding: 0 10px 0 10px;
      &-right {
        padding: 0 8px 0 8px;
      }
    }
    .basket-toggle-btn {
      &.mat-button-disabled {
        opacity: 0.4;
      }
    }

    .product-action-icons {
      position: absolute;
      right: 0;
      top: -10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: flex-start;
    }

    .close-btn {
      &.mat-button-disabled {
        .mat-icon {
          opacity: 0.3;
        }
      }
    }
    img {
      min-width: 40px;
      max-width: 40px;
      max-height: 40px;
    }
  }

  .loading-overlay {
    @include product-overlay;
    @include flex($align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .error-overlay {
    @include product-overlay;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
      color: var(--error-background);
    }
    button {
      margin: 0 8px;
    }
  }

  .action-panel {
    position: relative;
  }

  .p-autocomplete, .p-autocomplete-clearable {
    width: 100%;
    input {
      width: 100%;
    }
  }


  .p-inputtext {
    width: 100%;
  }

}

.errors-container {
  @include flex($flex-direction: column, $align-items: flex-end);
}

mat-option.product-option {
  height: unset !important;
  white-space: normal;
  padding: 0 8px;
  line-height: 40px;
  p {
    margin: 0;
  }
  .mat-option-text {
    p {
      margin-left: 16px;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }
    strong {
      font-size: 14px;
      line-height: 16px;
    }
    img {
      min-width: 40px;
      max-width: 40px;
      max-height: 40px;
    }
  }
  .product-title {
    @include flex($justify-content: space-between);
    padding-bottom: 8px;
    margin-left: 16px;
  }
  .product-item {
    @include flex;
    border-bottom: 1px solid;
    border-color: var(--main-border-color);
    padding: 8px 0;
  }
}

.product-autocomplete-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .product-name {
      max-width: 350px;
      margin-right: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .product-price {
    font-weight: bold;
  }
}
.treatment-plan-products-tooltip {
  width: unset;
  max-width: 500px;
}
.treatment-plan-products-tooltip .p-tooltip-text {
  width: unset;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19) !important;
}

