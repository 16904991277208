@mixin light-theme {
  --main-body-background: #{$main-grey};
  --main-text-color: #{$dark-grey};
  --secondary-text-color: rgba(#{$dark-grey-rgb}, 0.7);
  --success-text-color: #{$bright-green};
  --warning-text-color: #{$bright-orange};
  --danger-text-color: #{$bright-red};
  --login-background: #{$main-grey};
  --login-input-background: #{$mid-grey};
  --header-background: #{$main-white};
  --header-border-color: #{$mid-grey};
  --main-title-color: #{$pale-blue};
  --main-title-color-hover: #{$pale-blue-highlight};
  --secondary-title-color: #{$deep-grey};
  --main-panel-color: #{$main-white};
  --main-panel-color-hover: #{$main-grey};
  --main-border-color: #{$mid-grey};
  --pet-profile-border-color: #{$main-black};
  --panel-background: #{$main-white};
  --expand-panel-background: #{$mid-grey};
  --autocomplete-active-background: #{$main-grey};
  --search-border-color: #{$mid-grey};
  --search-background: #{$main-white};
  --table-background: #{$main-white};
  --table-background-disabled: rgba(199, 199, 199, 0.4);
  --table-header-background: #{$mid-grey};
  --table-row-hover-background: #{darken($main-white, 5%)};
  --primary-button-text: #{$main-white};
  --accent-button-background: #{$bright-orange};
  --success-btn-background: #{$bright-green};
  --input-toggle-dark-background: #{$pale-blue-deepen};
  --input-background: #{$main-grey};
  --error-background: #{$error-red};
  --error-background-faded: #{$red-pale};
  --warning-background: #{$bright-orange};
  --warning-background-faded: #{$orange-light};
  --success-background: #{$bright-green};
  --success-background-faded: #{$green-light};
  --progress-spinner-stroke: #{$pale-blue};
  --button-hover-color: rgba(33, 36, 41, 0.7);
  --video-call-background-color: #{$mid-grey};
  --overlay-background: rgba(#{$main-white}, .7);
  --skeleton-gradient: linear-gradient(90deg, rgba(#{$skeleton-animation-light}, 0) 0, rgba(#{$skeleton-animation-light}, 0.2) 20%, rgba(#{$skeleton-animation-light}, 0.5) 60%, rgba(#{$skeleton-animation-light}, 0));
  --rota-block-background-color: #f77b72;
  --rota-appointment-background-color: 2,117,216;
  --rota-rota-background-color: 37,196,64;
  --disabled-accent: #{$mid-grey};
  --prime-sidepanel-btn-hover-background: #{$main-grey};
  --edd-signature-background: #{$main-white};
  --prime-table-header: #{$main-grey};
  --prime-table-data-row: #{$main-white};
  --prime-table-border-color: #{$mid-grey};
  --prime-dropdown-selected-option: #{$pale-grey};
  --consultation-section-background: #{$mid-grey};
  --profile-preview-background: #{$main-white};
  --profile-preview-text: #{$main-black};
  --pom-product-highlight: #{$purple-dark};
  --address-panel-background: #{$mid-grey};
  --basket-total-background: #{$deep-grey};
  --basket-sidebar-background: #{$main-white};
  --basket-address-icon-fill: #{$main-black};
  --basket-processing-background: #{$bright-blue};
  --basket-complete-background: #{$dark-green};
  --outline-button-text: #{$pale-blue};
}
