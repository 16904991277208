app-add-edit-appointment {
  .main-holder {
    position: relative;
    padding: 30px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .radio-input-holder {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .input-holder {
    margin-bottom: 10px;
  }
  .appointments-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $sp-sm;
  }
  .appointment-card {
    @include flex($flex-direction: column, $justify-content: flex-start, $align-items: center);
    margin-right: 10px;
    padding: 0;
    position: relative;
    border-radius: $panel-border-radius;
    text-align: left;

    &.booking-error {
      border: 1px solid var(--error-background);
    }

    .appointment-card-header {
      position: relative;
      background: var(--main-title-color);
      width: 100%;
      border-top-left-radius: $panel-border-radius;
      border-top-right-radius: $panel-border-radius;
      padding: $sp-sm;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 500;
        font-size: 16px;
        color: white;
        strong {
          color: white;
        }
        &.appointment-type {
          display: block;
          padding-left: $sp-lg;
          font-size: 14px;
          font-weight: 400;
          color: #e6e6e6;
        }
      }
    }

    .appointment-card-content {
      padding: 10px;
      align-self: flex-start;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .actions {
        align-self: center;
        justify-self: flex-end;
      }
    }

    .appointment-date {
      display: flex;
      align-items: center;
      mat-icon {
        color: white;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    p {
      margin-bottom: 10px;
    }
  }
  .inputs-container {
    mat-select {
      margin-bottom: 8px;
    }
  }
  mat-label {
    font-size: 14px;
    font-weight: $bold-default;
    display: block;
    margin-bottom: 4px;
  }
  .date-input-container {
    position: relative;
    padding: 4px 0;
    margin-bottom: 16px;
    border-bottom: 1px solid;
    min-height: 30px;
    border-color: var(--main-border-color);
    .calendar-trigger {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    mat-icon {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .radio-btn {
    padding: 0 8px 8px 0;
  }

  .practice-input {
    margin-bottom: $sp-sm;
  }

  .margin-r-md {
    margin-right: $sp-md;
  }

  .actions {
    @include flex($justify-content: center);
    margin: 10px;
  }

  .appointments-grid {
    width: 100%;
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: repeat( auto-fit, minmax(350px, 36%) );
    justify-content: center;
  }

  app-appointment-type-picker {
    display: block;
    margin-bottom: 8px;
  }

  .confirm-btn, .back-btn {
    margin-right: 8px;
  }

  .appointment-btn .mat-icon, .confirm-btn .mat-icon {
    margin-right: 8px;
    cursor: pointer;
  }
  button {
    cursor: pointer;
  }

  .reschedule-btn {
    margin-right: $sp-sm;
  }

}

.treatment-plan-dialog-calendar-actions {
  @include flex($align-items: center, $justify-content: center);
  width: 100%;
  margin-top: 8px;
  mat-label {
    margin-right: 8px;
  }
  .cancel-btn {
    margin-right: 8px;
  }
}

.treatment-plan-dialog-calendar-picker-actions {
  @include flex($align-items: center, $justify-content: flex-end);
  width: 100%;
  margin-top: 8px;
  mat-label {
    margin-right: 8px;
  }
  .cancel-btn {
    margin-right: 8px;
  }

  .appointment-dialog-content {
    overflow: hidden;
  }

  .pet-selection-margin {
    margin-bottom: 150px;
  }
}
