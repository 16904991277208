@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

app-background {
  section.background-container {
    border: 1px solid;
    border-color: var(--main-border-color);
    background: var(--panel-background);
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    @include flex($flex-wrap: wrap);
    .background-col {
      flex-basis: 50%;
    }
    .inputs-row {
      @include flex($flex-wrap: wrap);
    }
    .inputs-col {
      flex-basis: 50%;
    }
  }

  .pet-banner {
    background: var(--panel-background);
    border-radius: 4px;
    border: 0 solid;
    padding: 10px;
    * {
      text-align: center;
    }
    .flex-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 16px;
        font-weight: $bold-default;
        margin: 0;
        padding-top: 10px;
        line-height: 24px;
        animation-name: fadeIn;
        animation-duration: 4000ms;
        &.faded {
          padding-top: 4px;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    app-pet-profile-image {
      display: block;
      animation-name: fadeIn;
      animation-duration: 3000ms;

      img {
        width: 100px;
        height: 100px;
      }
    }


    hr {
      padding:  1px 24px
    }
    .details {
      @include flex($flex-wrap: wrap ,$align-items: center, $justify-content: space-between);
      max-width: 50vw;
      min-width: 30vw;
      * {
        padding: 0 24px;
      }
    }
    .background-grid {
      display: grid;
      column-gap: 8px;
      row-gap: 24px;
      @include media-breakpoint-up(xxl) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include media-breakpoint-between(lg, xxl) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include media-breakpoint-between(md, lg) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include media-breakpoint-between(sm, md) {
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-between(xs, sm) {
        grid-template-columns: 1fr;
      }
      p {
        font-size: 14px;
        font-weight: $bold-default;
        margin: 0;
        padding-bottom: 4px;
        line-height: 24px;
        animation-duration: 1.5s;
        animation-name: zoomIn;
      }
      p:nth-of-type(2) {
        font-size: 12px;
        padding-bottom: 0;
        font-weight: $default-weight;
      }
      > div {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: 0px;
          top: 50%;
          height: 50%;
          width: 1px;
          border-right: 1px solid;
          animation-name: fadeIn;
          animation-duration: 1500ms;
          transform: translateY(-50%);
          border-color: var(--main-border-color);
        }
        @include media-breakpoint-up(xxl) {
          &:nth-child(3n) {
            &:before {
              border: none;
            }
          }
        }
        @include media-breakpoint-between(lg, xxl) {
              &:nth-child(3n) {
                &:before {
                  border: none;
                }
              }
        }
        @include media-breakpoint-between(md, lg) {
          &:nth-child(3n) {
            &:before {
              border: none;
            }
          }
        }
        @include media-breakpoint-between(sm, md) {
          &:nth-child(2n) {
            &:before {
              border: none;
            }
          }
        }
        @include media-breakpoint-between(xs, sm) {
          &:nth-child(1n) {
            &:before {
              border: none;
            }
          }
        }
      }
    }
  }
  .source {
    mat-icon, span {
      color: var(--secondary-title-color);
    }
  }
  .appointment-details {
    @include flex($align-items: center);
    mat-icon {
      margin-right: $sp-xs;
      font-size: 18px;
      height: 18px;
      width: 18px;
      line-height: 18px;
    }
  }
  app-charity-tag {
    display: block;
    margin-top: $sp-sm;
  }
}
