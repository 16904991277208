app-edd-form-media {
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .media-preview-holder {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    border: 2px solid grey;
    cursor: pointer;
    overflow: hidden;
    width: 80px;
    max-height: 80px;
  }
  .video-preview, .doc-preview, .image-preview {
    width: 80px;
    height: 80px;
    text-align: center;
  }

  .video-preview .mat-icon, .doc-preview .mat-icon {
    font-size: 30px;
    width: 30px;
    margin-top: 20px;
    color: darkgray;
  }

  .hover-preview {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    position: absolute;
    top: 0;
    left: -100%;
  }

  .hover-preview .mat-icon {
    font-size: 30px;
    width: 30px;
    margin-top: 30%;
    color: lightgrey;
  }

  .media-preview-holder:hover .hover-preview {
    left: 0;
  }

}

