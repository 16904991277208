app-queue-card-queue-details {
  .queue-details-container {
    * {
      font-size: 14px;
    }
    span {
      &.green {
        color: var(--success-background);
      }
      &.orange {
        color: var(--warning-background);
      }
      &.red {
        color: var(--error-background);
      }
    }
  }
}
