app-pet-history {
  $toolbar-height: 28px;

  .toggle-container {
    border: 1px solid;
    border-color: var(--main-border-color);
    border-radius: 4px;
    display: inline-flex;
    margin-bottom: 10px;
    cursor: pointer;
    max-height: $toolbar-height;
    overflow: hidden;

    &:not(:first-of-type) {
      margin-left: 10px;
    }

    .toggle-item {
      background: var(--header-background);
      color: var(--main-text-color);
      font-size: 12px;
      padding: 4px;

      mat-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
      }

      &:hover {
        background: var(--main-border-color);
      }

      &.selected {
        font-weight: $bold-default;
        background: var(--main-border-color);
      }

      &:first-child {
        border-right: 1px solid;
        border-color: var(--main-border-color);
      }
    }
  }


  .mat-table {
    width: 100%;
    margin-bottom: 1rem;

    .mat-column-actions {
      width: 150px;
      text-align: center !important;
    }

    .mat-column-created {
      width: 130px;
    }

    .new-note-input {
      margin-top: 10px;
    }

    .new-note-count {
      display: inline-block;
      margin-bottom: 10px;
    }

    tr {
      th.mat-header-cell {
        background: var(--table-header-background);
        color: var(--main-text-color);
      }

      td.mat-cell {
        background: var(--table-background);
        border-top: none;
        border-bottom: 1px solid;
        border-color: var(--main-border-color);
        text-align: left;

        &.problems {
          background: transparent;
        }
      }
    }

    tr.problem {
      @include problem-color-modifiers;
    }
  }

  section.toolbar {
    margin-top: 4px;
    @include flex;
  }

  .toolbar-input-infix {
    margin-left: 10px;

    .mat-form-field-infix {
      border-top: none;
    }
  }
  .no-table {
    width: 100%;
    min-height: 100px;
    position: relative;
    background: var(--main-body-background);

    .table-error-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include flex($align-items: center);
      p {
        margin: unset;
      }
      mat-icon {
        margin-right: 8px;
      }
    }

  }
}
