app-treatment-plan-lab-tests {
  @mixin labtest-overlay {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    z-index: 1;
    background: var(--overlay-background);
  }
  .add-problem-panel {
    background: var(--main-body-background);
    padding: 10px;
    border-radius: $panel-border-radius;
    border: 1px solid;
    border-color: var(--main-border-color);
    min-height: 100px;
    .picker-title {
      @include flex($justify-content: space-between);
      .mat-icon-button {
        position: relative;
        top: -5px;
      }
    }
    .products-search.form-control {
      width: 100%;
      text-overflow: ellipsis;
      padding-left: 8px;
      margin-bottom: 24px;
      background: none;
      border: none;
      border-bottom: 1px solid;
      border-color: var(--main-border-color);
      border-radius: 0;
      font-size: 14px;
      &::placeholder {
        color: var(--main-text-color);
      }
    }
  }
  .select-products {
    margin-top: 10px;
  }
  .selected-product {
    position: relative;
    padding: 8px 0;
    padding-right: 32px;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    border-color: var(--main-border-color);
    .flex-container {
      @include flex;
    }
    .product-desc {
      padding-left: 8px;
      p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0;
        &:first-of-type {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 8px;
        }
        small {
          line-height: 16px;
        }
      }
    }
    mat-form-field {
      width: 100%;
    }
    .product-inputs {
      padding: 0 8px 0 24px;
    }
    mat-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    img {
      min-width: 40px;
      max-width: 40px;
      max-height: 40px;
    }
  }
  .loading-overlay {
    @include labtest-overlay;
    @include flex($align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .error-overlay {
    @include labtest-overlay;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
      color: var(--error-background);
    }
    button {
      margin: 0 8px;
    }
  }

  .action-panel {
    position: relative;
  }
}

mat-option.product-option {
  height: unset !important;
  white-space: normal;
  padding: 0 8px;
  line-height: 40px;
  p {
    margin: 0;
  }
  .mat-option-text {
    p {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
      &:first-of-type {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
      }
      small {
        line-height: 16px;
      }
    }
    img {
      min-width: 40px;
      max-width: 40px;
      max-height: 40px;
    }
  }
  .product-item {
    @include flex;
    border-bottom: 1px solid;
    border-color: var(--main-border-color);
    padding: 8px 0;
  }
}
