.highcharts-strong {
  font-weight: $bold-default;
}
.highcharts-credits {
  display: none;
  fill: transparent;
}

.highcharts-background {
  fill: var(--table-background);
}
