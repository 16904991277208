app-maintenance-dialog {
  .maintenance-container {
    padding: 1rem;
    @include flex($flex-direction: column ,$align-items: center, $justify-content: flex-start);
    align-items: center;
  }
  .maintenance-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .title {
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 36px;
    font-weight: $bold-default;

    mat-icon {
      font-size: 32px;
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }
  }

  p {
    font-size: 16px;
    text-align: center;
  }

  .actions {
    margin-top: 48px;
    height: 40px;
    width: 100%;
    position: relative;
  }

  .download-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .countdown {
    font-size: 16px;
    color: var(--main-title-color);
  }
}
