app-user-order-history {
  min-width: 65vw;
  min-height: 45vh;
  display: block;
  position: relative;

  .order-history-header-container {
    @include flex($align-items: center);
    margin-bottom: $sp-md;
    mat-icon {
      margin-right: $sp-sm;
      color: var(--main-title-color);
    }
    h6 {
      margin-bottom: 0;
    }
  }

  .empty-container, .loading-container {
    @-webkit-keyframes fadeIn {
      0% { opacity: 0; }
      20% { opacity: 0; }
      40% { opacity: 0.3; }
      60% { opacity: 0.5; }
      80% { opacity: 0.9; }
      100% { opacity: 1; }
    }

    @keyframes fadeIn {
      0% { opacity: 0; }
      20% { opacity: 0; }
      40% { opacity: 0.3; }
      60% { opacity: 0.5; }
      80% { opacity: 0.9; }
      100% { opacity: 1; }
    }

    @include flex($align-items: center, $justify-content: center);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-name: fadeIn;
    animation-duration: 1s;
    p {
      margin-bottom: 0;
    }
    mat-icon {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 28px;
      margin-right: $sp-xs;
    }
    mat-progress-spinner {
      margin-right: $sp-md;
    }
  }

  td img {
    max-height: 40px;
  }
  .orders-table-container {
    margin: 0 0 $sp-md 0;
  }
  .product-table, .orders-table {
    width: 100%;
    tr {
      cursor: pointer;
    }
  }
  .product-table {
    margin: $sp-md 0;
    tr, td {
      background: var(--table-background) !important;
    }
    td {
      border-bottom: 1px solid var(--main-border-color) !important;
    }
  }

  .mat-column-price_paid {
    mat-icon {
      color: var(--main-title-color);
      font-size: 18px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      margin-right: $sp-sm;
    }
  }

  .mat-column-shipping_refunded {
    mat-icon {
      color: var(--error-background);
    }
  }

  .mat-column-subscription {
    mat-icon.no-subscription {
      color: var(--error-background);
    }
    mat-icon.subscription {
      color: var(--success-background);
    }
  }

  .search-container {
    position: relative;
    @include flex($align-items: center);
    mat-form-field {
      margin-right: $sp-sm;
    }
    .empty-container-inline {
      @include flex($align-items: center);
      mat-icon {
        margin-right: $sp-sm;
      }
      p {
        margin-bottom: 0;
        max-width: 25vw;
      }
    }

    mat-date-range-input {
      margin-right: 4px;
    }

    .mat-date-range-input-inner {
      text-align: left;
    }

    .mat-form-field {
      width: $search-date-picker-width;
      margin-right: 0;
      top: -5px;
    }

    .mat-date-range-input {
      position: relative;
      top: -2px;
    }

    .mat-form-field-infix {
      border: none;
      text-align: right;
    }

    .mat-form-field-label-wrapper {
      display: none;
    }

    .mat-button-wrapper svg {
      width: 24px;
      position: relative;
      top: -3px;
    }

    .mat-date-range-input-separator {
      color: var(--main-text-color);
    }

    .clear-btn {
      right: $sp-xs;
      top: -15px;
      .mat-form-field {
        margin-right: 16px;
      }
      mat-icon {
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-size: 18px;
      }
    }



  }

  .blur {
    filter: blur(12px);
  }
}
