app-rota-preview-shift {
  .heading, .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .preview-heading {
      font-size: 1rem;
      font-weight: 600;
      color: var(--secondary-text-color);
      display: flex;
      align-items: center;
      .back-button {
        margin-right: $sp-md;
      }
    }

    p-avatar {
      display: block;
      margin-left: $sp-lg;
    }
  }

  .actions {
    margin-left: $sp-xxl;
  }

  h4 {
    font-size: 16px;
    color: var(--main-text-color);
  }

  .content-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 1.5rem;

    i {
      margin-right: $sp-sm;
      font-size: 1.5rem;
      color: var(--main-title-color);
      font-weight: 600;
    }

    .actions {
      p-button {
        margin-left: $sp-sm;
      }
    }
  }

  .content-heading > span {
    display: flex;
    align-items: center;
  }

  .preview-content-heading * {
    font-size: 1.25rem;
  }

  .content {
    margin-top: 1.5rem;

    table {
      display: table;
      border-collapse: separate;
      box-sizing: border-box;
      text-indent: initial;
      border-spacing: 2px;
      width: 100%;

      tr {
        height: 2.5rem;
        vertical-align: middle;
      }

      td {
        font-size: 1rem;
        font-weight: 500;
        color: var(--main-text-color);

        &.title {
          width: 25%;
          color: var(--secondary-text-color);

          &.services {
            vertical-align: top;
          }
        }
      }
    }

    .staff-data {
      display: flex;
      align-items: center;
      column-gap: $sp-sm;

      span {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .services-grid {
      display: flex;
      flex-wrap: wrap;
      column-gap: $sp-xs;
      row-gap: $sp-xs;
      width: calc(100% - 25%);

      .p-chip {
        font-size: 0.8rem;
      }
    }
  }

  button.p-button .p-button-icon {
    color: unset !important;
  }

  //.p-button.p-button-icon-only.p-button-rounded {
  //  height: 2rem !important;
  //  width: 2rem !important;
  //
  //  .pi {
  //    font-size: 0.7rem !important;
  //  }
  //}

  .p-tag {
    background: unset;
  }

  .p-button.p-button-secondary.p-button-text {
    background: rgba(96, 125, 139, 0.04);
  }

}
