.mat-snack-bar-container {
  span {
    color: var(--primary-button-text);
  }
  ::first-letter {
    text-transform: uppercase;
  }

  &.info, &.announcement {
    background-color: royalblue;
  }

  &.success {
    background-color: var(--success-background);
  }

  &.error {
    background-color: var(--error-background);
  }

  &.warning {
    background-color: var(--warning-background);
  }
}

.p-toast-message-text, .p-toast-summary, .p-toast-detail, .p-toast-icon-close-icon {
  color: $dark-grey;
}


