app-rota-preview-shift {
  .shift {
    display: flex;
    flex-direction: column;
    row-gap: $sp-sm;
    column-gap: $sp-sm;

    .time-container {
      display: flex;
      align-items: center;
      label {
        padding-right: $sp-xs;
      }
      p-calendar {
        margin-left: $sp-sm;
        width: 100%;
      }
    }

    .p-inputtextarea {
      height: 64px;
    }

    .p-calendar .p-inputtext {
      width: unset;
    }
    .p-inputtext, .p-inputtextarea, .p-calendar, .p-autocomplete, .p-multiselect {
      width: 100%;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      margin-bottom: $sp-xs;
      label {
        padding-right: $sp-xs;
      }
      i {
        padding-right: $sp-sm;
      }
    }

    .p-tag-icon {
      margin-left: $sp-xs;
      color: white;
    }
    .p-tag {
      background: var(--main-title-color);
      cursor: pointer;
    }
  }

  .form-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .rotaScroll {
    .p-scrollpanel-bar {
      background: var(--main-title-color);
    }
  }
}
