app-appointment-listing {
  .appointment-listing-card {
    border: 1px solid;
    border-color: var(--main-title-color);
    padding: $sp-sm;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: $sp-sm 0;

    i.pi {
      font-size: 1.3rem;
    }
    .details {
      margin-left: $sp-sm;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      p {
        margin: 0;
      }
    }
    .appointment-changes-label {
      display: flex;
      align-items: center;
      color: var(--main-title-color);
      i.pi {
        color: var(--main-title-color);
      }
    }
    .listing-actions {
      margin-left: auto;
      align-self: center;
      display: flex;
      i.pi {
        margin: 0 $sp-xs;
        color: var(--main-title-color);
        &.disabled {
          color: var(--disabled-accent);
        }
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }
  .modification-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $sp-md;
    button {
      margin: 0 $sp-sm;
    }
  }
  .empty-list span {
    position: relative;
    top: -7px;
  }
  .loading-overlay {
    @include flex($align-items: center, $justify-content: center);
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
      margin-left: 16px;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }
}

.appointment-preview-cancel-actions {
  width: 100%;
  margin-top: $sp-lg;
  display: flex;
  justify-content: center;
  button {
    margin: 0 $sp-md;
  }
}

.cancel-appointment-dialog {
  .mat-dialog-content {
    width: 600px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}


