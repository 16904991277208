app-calendar {
  .calendar-toolbar {
    @include flex($flex-wrap: wrap, $justify-content: space-between);
    position: relative;
    padding-right: $sp-sm;
    margin-bottom: $sp-sm;
    .items-group {
      @include flex($flex-wrap: wrap);
    }

    h4 {
      justify-content: center;
      align-self: flex-start;
      margin-top: 8px;
      margin-bottom: 0;
    }

    button {
      background: var(--main-panel-color);
      color: var(--main-title-color);
      border-color: var(--header-border-color);
      border: 1px solid;
      border-radius: $button-border-radius;
      margin-right: $sp-sm;

      span {
        color: var(--main-title-color);
        font-weight: $bold-default;
      }
      &:focus {
        outline: none;
      }
      &.left-button, &.right-button {
        background: var(--main-title-color);

        span {
          color: var(--primary-button-text);
        }
      }
    }

    .button-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      button {
        background: var(--main-panel-color);
        color: var(--main-title-color);
        border-color: var(--header-title-color);
        border: 1px solid;
        margin-right: 0;
        border-radius: 0;

        span {
          color: var(--main-title-color);
        }

        &:first-of-type {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-top-left-radius: $button-border-radius;
          border-bottom-left-radius: $button-border-radius;
        }
        &:last-of-type {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-top-right-radius: $button-border-radius;
          border-bottom-right-radius: $button-border-radius;
        }
        &.selected {
          outline: none;
          background: rgba(#d8d8d8, 0.1);
          border-color: var(--main-title-color);
        }
      }
    }

  }
}
