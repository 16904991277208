.expanded-details {
  text-transform: none;
  text-align: center;
  padding: 50px 0;

  .mat-progress-spinner {
    margin: 0 auto;
  }

  .pet-thumb {
    width: $expand-panel-thumbs-size;
    height: $expand-panel-thumbs-size;
    display: inline-block;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.mat-table {
  tr.example-detail-row {
    height: 0;

    td {
      background: var(--expand-panel-background);
      border-bottom: none;
    }
  }
}

