app-address-selection {
  .field-checkbox {
    margin-bottom: 10px;
  }
  .actions-container {
    display: flex;
    margin-top: $sp-md;
    button {
      margin: 0 $sp-xs;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  button, p-dropdown {
    margin-top: $sp-sm !important;
  }
  .p-dropdown, p-dropdown {
    width: 100%;
  }
}

.address-selection-dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .address-selection-items {
    margin-top: $sp-md;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ul {
      list-style-type: none;
      margin-block-start: 0;
      padding-inline-start: 0;
      max-height: 40vh;
      overflow-x: hidden;
      overflow-y: auto;
      li {
        padding: $sp-md;
        border-radius: 8px;
        border: 1px solid var(--main-border-color);
        margin-bottom: $sp-xs;
        display: flex;
        justify-content: space-between;
        align-items: center;
        mat-icon {
          position: relative;
          top: 2px;
          margin-left: $sp-sm;
          cursor: pointer;
          &:hover {
            opacity: 70%;
          }
        }
      }
    }
    button {
      display: inline-block;
      margin: 0 $sp-xs;
    }
  }
  .postcode-input {
    margin-right: 20px;
  }

  .postcode-find-btn {
    margin-top: $sp-xs;
  }

  .postcode-search {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    p-autoComplete {
      flex-grow: 1;
      margin-right: $sp-sm;

      .p-autocomplete {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
    button {
      min-width: 125px;
      margin-top: 0 !important;
    }
    mat-icon {
      margin-right: $sp-sm;
    }
  }

  .address-group-inline {
    display: inline-block;
    margin-top: $sp-sm;
    width: 100%;
    label {
      font-weight: 500;
      font-size: 13px;
      margin-right: $sp-md;
    }

    .full-width {
      .p-inputtext {
        width: 100%;
      }
    }
    input {
      margin-top: 0;
      font-size: 13px;
    }
    mat-error {
      margin-left: $sp-md;
    }
  }

  form {
    margin-top: $sp-md;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    button {
      width: fit-content;
    }
  }

    .actions {
    display: flex;
    justify-content: center;
    button {
      margin: 0 $sp-sm;
    }
  }
}
