app-payment-processing-state {
  .process-container {
    height: 300px;
    position: relative;
  }

  .terminal-connecting, .payment-pending, .payment-successful, .payment-warn, .payment-error {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .loader {
      margin-top: 16px;
      position: relative;
      span{
        width:16px;
        height:16px;
        border-radius:50%;
        display:inline-block;
        position:absolute;
        left:50%;
        margin-left:-10px;
        animation: jumpingAnimation 1s 0.1s ease-in infinite;

      }
      span:nth-child(1){
        left: -28px;
        background:var(--main-title-color-hover);
        animation: jumpingAnimation 1.2s 0.1s ease-in-out infinite;

      }
      span:nth-child(2){
        background:var(--main-title-color);
        z-index:100;
        animation: jumpingAnimation 1.2s 0.3s ease-in-out infinite;
      }
      span:nth-child(3){
        background:var(--main-title-color);
        z-index:100;
        left: 28px;
        animation: jumpingAnimation 1.2s 0.5s ease-in-out infinite;
      }
    }
  }

  .payment-successful {
    h1 {
      color: var(--success-background)
    }
    mat-icon {
      width: 64px;
      height: 64px;
      font-size: 64px;
      line-height: 64px;
      color: var(--success-background);
    }
  }
  .payment-warn {
    h1 {
      color: var(--warning-background)
    }
    mat-icon {
      width: 64px;
      height: 64px;
      font-size: 64px;
      line-height: 64px;
      color: var(--warning-background);
    }
  }

  .payment-error {
    h1 {
      color: var(--error-background)
    }
    mat-icon {
      width: 64px;
      height: 64px;
      font-size: 64px;
      line-height: 64px;
      color: var(--error-background);
    }
  }
}



@keyframes jumpingAnimation {
  0% {
    transform: translate3d(0, 0,0);
  }
  50% {
    transform: translate3d(0, 20px,0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
