:host {
  width: 100%;
}

app-pet-selector {
  .user-search, .pet-search {
    display: block;
    margin-bottom: $sp-md;
  }
  .pet-label {
    mat-label {
      display: inline;
    }
    mat-spinner {
      display: inline;
      margin-left: $sp-sm;
    }
  }
  p-tag {
    margin-bottom: 8px;
    margin-top: -8px;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      margin-top: 0;
    }
  }
}
