@mixin pet-profile-overlay {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100%);
  border-radius: 10px;
  z-index: 1;
  background: var(--overlay-background);
}


app-edd-form-group {
  --min-cols: 1;
  --max-cols: 2;
  --cols-min-width: 300px;
  --grid-row-gap: #{$sp-md};
  --grid-column-gap: #{$sp-md};
}

app-edd-form-compact {
  .pet-profile-container {
    position: relative;
  }

  .main-holder {
    position: relative;
    padding: 30px;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 100px;
  }

  .data-points-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(min((100%/var(--min-cols) - 10px*(var(--min-cols) - 1)/var(--min-cols)),
        max(var(--cols-min-width),
          (100%/var(--max-cols) - 10px*(var(--max-cols) - 1)/var(--max-cols)))), 1fr));
    gap: 10px;
  }
  .group-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: $sp-md;
    > * {
      margin-left: $sp-md;
    }
  }


  .input-container {
    display: flex;
    align-items: flex-start;
    > div {
      width: 100%;
    }
    mat-form-field {
      width: 100%;
    }
  }

  .loading-overlay {
    @include pet-profile-overlay;
    @include flex($align-items: center, $justify-content: center);

    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }

    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .error-overlay {
    @include pet-profile-overlay;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);

    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
      color: var(--error-background);
    }

    button {
      margin: 0 8px;
    }
  }

  .action-panel {
    position: relative;
    padding: 0;
  }

  .profile-thumbnail {
    min-width: 150px;
    cursor: pointer;
    display: inline-block;
    padding: 20px;
    border: 1px solid;
    background: var(--main-panel-color);
    border-color: var(--main-border-color);
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 10px;

    &.error-highlight {
      border-color: var(--error-background);
    }
  }

  .thumbnail-holder .profile-thumbnail:first-child {
    margin-left: 0;
  }

  .profiles-column {
    border-right: 1px solid;
    border-color: var(--main-border-color);
    padding-left: 0;
    padding-right: 0;
  }

  .profile-btn {
    cursor: pointer;
    padding: 5px;
  }

  .expand-btn {
    display: block;
    margin: 30px auto 0 auto;
  }

  .collapse-btn {
    position: absolute;
    top: 10px;
    right: 30px;
  }

  .data-point-image-holder {
    margin-bottom: $sp-sm;
  }

  .question-column {
    height: 430px;
    padding: $sp-sm;
    overflow: auto;
    background: var(--main-panel-color);
  }

  .answer-count {
    float: right;
    font-weight: $bold-default;
  }

  .selected {
    background: var(--expand-panel-background);
  }

  .defaults-check {
    margin-top: 10px;
  }

  .defaults-check .defaults-check-message {
    position: relative;
    top: -3px;
    margin-left: 10px;
  }

  .link-btn {
    background: #26c2eb;
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    position: relative;
  }

  .link-btn span {
    position: relative;
    top: -3px;
    margin-left: 5px;
  }

  .link-btn .mat-icon {
    position: relative;
    top: 2px;
  }

  .group-label {
    font-weight: $bold-default;
    margin-bottom: 10px;
  }

  app-edd-form-point {
    width: 100%;
  }

  hr {
    opacity: 0.5;
  }
}
