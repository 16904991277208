app-audio-level-indicator {
  --audio-level-container-size: 20px;
  --dot-size: 3px;
  --audioSmallScale: 0;
  --audioLargeScale: 0;
  .microphone-detection-container {
    display: flex;
    position: relative;
    background: var(--main-title-color);
    height: var(--audio-level-container-size);
    width: var(--audio-level-container-size);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .dot-one, .dot-two, .dot-three {
    width: var(--dot-size);
    height: var(--dot-size);
    background-color: white;
    color: white;
    z-index: 1;
    position: absolute;
    max-height: 10px;
    transition: all 20ms linear;
    box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
  }
  .dot-two {
    left: 50%;
    transition: all 20ms linear;
    transform: translateX(-50%) scale(1, var(--audioLargeScale));
  }

  .dot-one {
    left: 5%;
    margin: 0 2px;
    transition-delay: 5ms;
    transform: scale(1, var(--audioSmallScale));
  }

  .dot-three {
    left: 70%;
    transition-delay: 5ms;
    transform: scale(1, var(--audioSmallScale));
  }
}
