app-status {
  .status-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .user-container {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 10px;
    min-width: 87px;
  }
  mat-slide-toggle {
    margin-right: 10px;
  }
  .mat-icon-button {
    margin-top: -4px;
    &.action-disabled mat-icon {
      color: lightgrey;
    }
    mat-icon {
      font-size: 22px;
      height: 22px;
      width: 22px;
      line-height: 22px;
    }
  }
}
