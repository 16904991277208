app-home-holder {
  --header-height: #{$header-height};
  --sidebar-width: #{$menu-width};
  .page-holder {
    position: relative;
    &.checking-consult {
      filter: blur(4px);
    }
  }

  .home-holder {
    padding-left: 10px;
    padding-right: 20px;

    .col-lg-4 {
      padding-left: 10px;
      padding-right: 10px;

      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }
  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    stroke: var(--overlay-background);
    .loading-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: default;
      }
      mat-spinner {
        margin-right: 32px;
        circle {
          stroke: var(--main-title-color);
        }
      }
      p {
        font-size: 24px;
        margin: 0;
        color: var(--main-text-color);
      }
    }
  }
  .loading-panel {
    min-height: 250px;
    position: relative;
    overflow: hidden;
    //@include skeleton-animation;
    hr {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    &.status {
      min-height: 46px;
    }
    &.online {
      min-height: 225px;
    }
    &.queue {
      min-height: 75px;
    }
    &.card {
      min-height: 220px;
      margin-bottom: 0;
    }
  }
  .loading-content {
    position: relative;
    overflow: hidden;
    background: var(--main-border-color);
    @include skeleton-animation;
    width: 100%;
    border-radius: 4px;
    &.text {
      min-height: 16px;
      margin-bottom: 8px;
      &.inline {
        margin-right: 8px;
      }
      &.w-50 {
        width: 50%;
      }
      &.w-33 {
        width: 33.3%;
      }
      &.w-25 {
        width: 25%;
      }
    }
    &.slider {
      min-height: 24px;
      margin-bottom: 0;
      &.inline {
        margin-right: 8px;
      }
      &.w-50 {
        width: 50%;
      }
      &.w-33 {
        width: 33.3%;
      }
      &.w-25 {
        width: 25%;
      }
    }
    &.header {
      min-height: 24px;
      margin-bottom: 8px;
      &.inline {
        margin-right: 8px;
      }
      &.w-50 {
        width: 50%;
      }
      &.w-33 {
        width: 33.3%;
      }
      &.w-25 {
        width: 25%;
      }
    }
    &.profile {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 8px;
      flex-shrink: 0;
    }
    &.status {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
  .loading-flex {
    @include flex($align-items: center, $justify-content: space-between);
    &.start {
      justify-content: flex-start;
      align-items: center;
    }
  }
  app-messages {
    display: block;
    padding-left: 5px;
    padding-right: 14px;
    margin-bottom: 8px;
  }

  .answer-call-loading-holder {
    background: rgba(black, 0.3);
    position: fixed;
    border-radius: 3px;
    padding: $sp-md $sp-xl;
    width: calc(100vw - var(--sidebar-width));
    height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: var(--header-height);
    left: var(--sidebar-width);
    pointer-events: none;
    z-index: 100;

    .answer-call-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: $sp-md $sp-xl;
      flex-wrap: nowrap;
      box-shadow: 0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.1);
      border: 1px solid;
      border-color: var(--main-border-color);
      background: var(--panel-background);
      border-radius: 5px;
      p {
        margin: 0;
        margin-left: 24px;
      }
    }
  }
}
