.p-tieredmenu.p-tieredmenu-overlay {
  background: var(--header-background);
}

.p-button-label, .p-button .pi.p-button-icon {
  color: var(--primary-button-text);
}

.p-button-outlined .p-button-label,
.p-button-outlined .pi.p-button-icon,
.p-button-text .pi.p-button-icon {
  color: var(--outline-button-text);
}

.p-button-text.p-button-danger .p-button-label, .p-button-text.p-button-danger .pi.p-button-icon {
  color: var(--error-background);
}

.p-element.full-width, .full-width {
  .p-button, .p-dropdown, .p-inputtextarea {
    width: 100%;
  }
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background: var(--main-title-color);
  span {
    color: white;
  }
}

.p-tag {
  padding: 0.2rem 0.8rem;
  font-size: 1rem;
  background: var(--main-title-color);
}

.p-chip {
  padding: 0.2rem 0.75rem;
}

.p-tag-icon, .p-tag-value, .p-tag-icon.pi {
  color: white;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--main-title-color-hover);
  font-size: 24px;
  font-weight: 600;
  color: white;
  min-width: 2.5rem;
  height: 2.5rem;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  color: var(--secondary-text-color);
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: var(--main-text-color);
}

.autocomplete-full-width {
  width: 100%;

  .p-autocomplete, .p-autocomplete-input {
    width: 100%;
  }
}
