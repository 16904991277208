app-hills-reco-tool {
  $hills-sidebar-width: 350px;
  $actions-height: 40px;

  .hills-reco-tool-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 0;
    transition: all 0.2s linear;
    height: calc(100% - $actions-height);

    &.expanded {
      grid-template-columns: 1fr $hills-sidebar-width;
    }

    .iframe-container, iframe {
      height: 100%;
      width: 100%;
    }

    .reco-sidebar {
      width: 100%;
      padding: 0;
      background: var(--panel-background);
      height: 100%;
      transition: all 0.25s ease-in;
      box-shadow: none;
      overflow: hidden;

      h4 {
        margin-bottom: $sp-md;
      }

      &.expanded {
        padding: 0 16px;
      }

      .product-item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;

        .featured-image {
          flex-basis: 50px;

          img {
            max-height: 50px;
            max-width: 50px;
          }
        }

        .product-content {
          flex-shrink: 1;
          flex-basis: calc(100% - 50px);
          padding: 0 10px;

          .product-name {
            margin: 0;
            font-weight: 500;
            color: var(--main-text-color);
          }

          .product-summary {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            color: var(--secondary-text-color)
          }

          .product-price {
            font-size: 18px;
            font-weight: $bold-default;
            text-align: right;
            color: var(--main-text-color);
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:not(:last-child)::after {
          position: absolute;
          content: '';
          bottom: -13px;
          width: 100%;
          height: 1px;
          background: var(--main-border-color);
        }
      }

      .product-guide {
        color: var(--main-title-color);
        display: block;
        font-size: 12px;
        font-weight: 400;
        &.standalone {
          font-size: 14px;
        }
      }

      button.add-button {
        margin-top: $sp-md;
      }
      ul {
        margin-top: $sp-md;
        padding-inline-start: $sp-md;
        li {
          &::marker {
            color: #f44336;
          }
        }
      }
    }
  }
}
