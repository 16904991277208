app-client-basket-item {
  .pom-icon, .product-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 24px;
    * {
      fill: var(--main-title-color);
    }
  }
  .batch-selector {
    .p-multiselect-label-empty {
      visibility: visible;
    }
  }
  .product-header, .header-details {
    display: flex;
  }
  .header-details {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .product-item {
    padding: $sp-md 12px $sp-md 0;
    border-bottom: 1px solid var(--main-border-color);
    .product-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--main-text-color);
      margin:0;
      font-family: 'Roboto', sans-serif;
    }
    .product-price {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--main-text-color);
    }
    .product-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      padding-left: 48px;
      label, span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .p-inputnumber-input {
        width: 75px;
      }
      .p-inputnumber-button .p-button-icon {
        color: white;
      }
      .p-multiselect .p-multiselect-label {
        padding: 0.5rem;
      }
    }
    .product-actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 10px;
      padding-left: 48px;
      .remove-action {
        position: relative;
        left: -1rem;
        .p-button-label {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: none;
        }
      }
      .p-button-rounded {
        padding: 8px 22px;
        .p-button-label {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .error-label {
        margin-top: $sp-xs;
        color: var(--error-background);
        flex-basis: 100%;
        text-align: right;
      }
    }
  }
}
