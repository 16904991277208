app-client-registration {
  .mat-form-field-type-mat-select {
    position: relative;
    top: -1px;
  }

  .pet-form {
    padding: 10px;
    background: var(--main-body-background);
    margin-bottom: 10px;
    border-radius: $card-padding;
    position: relative;
  }

  .add-pet-btn {
    margin-bottom: 20px;
  }

  .remove-pet-btn {
    position: absolute;
    top: -17px;
    right: -17px;
  }

  .error-warning {
    .inline-icon {
      position: relative;
      top: 5px;
    }
  }

  .formgrid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .input-holder {
    display: inline-block;
    position: relative;
    text-align: left;
    width: calc(33.33% - 10px);
    &.note-input {
      width: 100%;
    }
    .char-count {
      color: var(--main-title-color);
      font-weight: bold;
      font-size: 14px;
      line-height: 12px;
    }
    label {
      display: block;
    }
    .p-multiselect, .p-inputtext, .p-dropdown, .p-inputnumber, p-inputnumber {
      width: 100%;
    }
    .p-inputnumber {
      height: 44px;
    }
    .p-inputnumber-button {
      .p-button-icon {
        color: white;
      }
    }
  }
}
