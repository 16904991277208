app-observations {
  @mixin observations-overlay {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    z-index: 1;
    background: var(--overlay-background);
  }
  .observations-container {
    position: relative;
  }
  .session-info-holder {
    float: right;

    .session-label {
      position: relative;
      top: -7px;
    }
  }
  .main-holder{
    position: relative;
    border: 1px solid;
    border-color: var(--main-border-color);
    background: var(--panel-background);
    border-radius: 4px;
    padding: 10px;
    min-height: 100px;
  }
  .header-container {
    @include flex($align-items: flex-start, $justify-content: space-between);
    margin-bottom: $sp-sm;
  }
  .empty-container {
    position: absolute;
    width: calc(100% - 10px);
    height: 100%;
    border-radius: 4px;
    z-index: 1;
    background: var(--overlay-background);
  }
  .response-grid {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    column-gap: 8px;
    row-gap: 16px;
  }
  .media-thumbnails {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    margin-top: 8px;
    img {
      position: relative;
      max-height: 120px;
      background-size: contain;
      height: auto;
    }
    video {
      max-height: 120px;
      max-width: 150px;
      width: 100%;
      background-size: contain;
      // @ToDo: Needs a specific color
      background: #e6e6e6;
    }
    .image-container, .video-container {
      margin-right: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // @ToDo: Needs a specific color
      background: #e6e6e6;
    }
    .image-overlay, .video-overlay {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(100%);
      background: transparent;

      mat-icon {
        font-size: 32px;
        color: transparent;
        position: absolute;
        left: calc(50% - 5px);
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        // @ToDo: Needs a specific color
        background: rgba(black, 0.4);
        cursor: pointer;
        mat-icon {
          // @ToDo: Needs a specific color
          color: white;
        }
      }

    }
  }
  .response-card {
    padding: 0 0 4px 0;
    border: 1px solid;
    border-top: 1px solid;
    border-color: var(--main-border-color);
    border-top-color: var(--main-title-color);
    background: var(--panel-background);
    border-radius: 10px;
    .response-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: var(--main-title-color);
      padding: 0 4px;
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        color: $white;
      }
    }
    .response-content {
      padding: 0 4px;
      position: relative;
      h6 {
        // question
        margin: 4px 0 0;
        font-size: 14px;
        color: var(--main-text-color);
        word-break: break-word;
      }
      p {
        margin: 4px 0 0;
        font-size: 12px;
        word-break: break-word;
      }
    }
    .location-grid {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      max-width: 100%;
      overflow: auto;
      .locations p {
        text-align: center;
      }
      .image-container {
        position: relative;
        margin: 4px 8px;
        cursor: pointer;
      }
      img {
        max-width: 80px;
      }
      .marker {
        position: absolute;
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
      }
    }
  }

  .loading-overlay {
    @include observations-overlay;
    @include flex($align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--main-title-color);
    }
  }

  .error-overlay {
    @include observations-overlay;
    @include flex($flex-direction: row, $align-items: center, $justify-content: center);
    p {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: $header-two-size;
      color: var(--error-background);
    }
    button {
      margin: 0 8px;
    }
  }

  .action-panel {
    position: relative;
  }
}

.empty-container {
  @include flex($flex-direction: row, $align-items: center, $justify-content: center);
  p {
    margin-bottom: 0;
    margin-left: 16px;
    font-size: $header-two-size;
  }
}

.observations-overlay-image-container, .observations-overlay-image-container img {
  max-width: 75vw;
  max-height: 60vh;
}
