app-timeline-chart {
  .no-chart {
    width: 100%;
    min-height: 100px;
    position: relative;
    fill: var(--table-background);
    margin-bottom: 10px;

    .error-message-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include flex($align-items: center);
      p {
        margin: unset;
      }
      mat-icon {
        margin-right: 8px;
      }
    }

  }
}
